import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import CrossButton from "../CrossButton/CrossButton";
import greyCrossIcon from "../../assets/images/crossDarkGrey.svg";
import getSiteHeaderFilterDisplay from "../../../helpers/Table/getSiteHeaderFilterDisplay";

const containerCN = cntl`
  flex
  flex-row
  w-full
  px-4
  ml-4
  overflow-x-auto
  scrollbar-hidden::-webkit-scrollbar
  scrollbar-hidden
`;

const tagCN = cntl`
  flex
  flex-none
  items-center
  border
  rounded-lg
  bg-es-extra-light-grey
  border-es-light-grey
  py-2
  px-3
  mr-2
  max-w-64
`;

const textCN = cntl`
  capitalize
  text-es-dark-grey
  text-es-sm
  font-es-semibold
  tracking-es-normal
  truncate
`;

const SiteHeaderSelectedFiltersAndGroups = ({
  filters,
  groups,
  setAllFilters,
  groupDispatch,
  setGroupBy,
}) => {
  const [filterLabels, setFilterLabels] = useState([]);

  useEffect(() => {
    const filterDict = {};
    filters?.forEach((filter) => {
      filterDict[`${filter.id}`] = {
        id: filter.id,
        display: getSiteHeaderFilterDisplay(filter.id, filter.value),
        type: "filter",
      };
    });
    const groupDict = {};
    groups?.forEach((group) => {
      groupDict[`${group.value}`] = {
        id: group.value,
        display: `Group By: ${group.label}`,
        type: "group",
      };
    });

    setFilterLabels([
      ...Object.values(filterDict),
      ...Object.values(groupDict),
    ]);
  }, [filters, groups]);

  const onRemove = ({ id, type }) => {
    if (type === "filter") {
      setAllFilters(filters.filter((item) => item.id !== id));
    } else if (type === "group") {
      const filteredGroups = groups.filter((item) => item.value !== id);
      groupDispatch({
        type: "updateAll",
        groups: filteredGroups,
      });
      setGroupBy(filteredGroups.map((g) => g.value));
    }
  };

  return (
    <div className={containerCN}>
      {filterLabels?.map(({ display, id, type }) => (
        <div className={tagCN} key={id}>
          <p className={textCN}>{display}</p>
          <div className="flex pl-2">
            <CrossButton
              onClick={() => {
                onRemove({ id, type });
              }}
              classNsame="px-2 py-2"
              icon={greyCrossIcon}
              style={{ height: "0.65rem", width: "0.65rem" }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

const filtersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({
      isTrue: PropTypes.bool,
      value: PropTypes.string,
      type: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  })
);

SiteHeaderSelectedFiltersAndGroups.propTypes = {
  filters: filtersPropType,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  setAllFilters: PropTypes.func,
  setGroupBy: PropTypes.func,
  groupDispatch: PropTypes.func,
};

SiteHeaderSelectedFiltersAndGroups.defaultProps = {
  filters: [],
  groups: [],
  setGroupBy: () => {},
  setAllFilters: undefined,
  groupDispatch: undefined,
};

export default SiteHeaderSelectedFiltersAndGroups;
