import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { set as _set } from "lodash";
import { FILTER_TYPES, TABLE_COLUMN_WIDTH } from "../../../constants";

import Input from "../Input/Input";
import Table from "../Table/Table";

import ImageCell from "../Table/ImageCell";
import AssetTableInLineForm from "./AssetTableInLineForm";
import DateCell from "../Table/DateCell";
import BooleanBullet from "../BooleanBullet/BooleanBullet";
import Widget from "../Widget/Widget";
import TagList from "../Tags/TagList";
import { useAssetSwitchView } from "../../../hooks/useSwitchView";
import CardGrid from "../CardGrid/CardGrid";
import useAttributes from "../../../hooks/useAttributes";
import aggregateTableRow from "../../../helpers/Table";

const containerCN = cntl`
`;
const bulletCN = (val) => cntl`
${val ? "flex bg-brandGreen mr-1 ml-5 mt-1" : ""}
`;

const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [
    [
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Subcategory",
        value: "subcategory",
      },
    ],
    // add more hierarchical groups
  ],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Property",
      value: "property",
    },
    {
      label: "Project",
      value: "project",
    },
    {
      label: "Spaces",
      value: "location",
    },
    {
      label: "Tags",
      value: "tags",
    },
    {
      label: "Owned By",
      value: "ownedBy",
    },
  ],
};

const PureAssetTable = ({
  allowSelection,
  className,
  data,
  isLoading,
  name,
  setAssets,
  onRowClick,
  onSaveRowClick,
  onEditRowCancelClick,
  onDeleteRowClick,
  onStartRowEditing,
  onEndRowEditing,
  onEditSaveCallback,
  onAddSaveCallback,
  propertyRef,
  templateSettings,
  updateUserTemplateSettings,
  deleteUserTemplateSettings,
  categoryOptionsList,
  subCatOptionsMap,
  categoryOptions,
  additionalDetailsColumnNames,
  showColumnSettingsLeft,
  fileActionsIcons,
  handleRowSelect,
}) => {
  const [isShowingAssetsTable] = useAssetSwitchView();
  const [selectedMeasurements] = useAttributes();

  const tableColumns = React.useMemo(() => {
    const onValueChange = (newValue, indexToEdit, accessor) => {
      setAssets((prevAssets) =>
        prevAssets.map((asset, index) => {
          if (index === indexToEdit) {
            return { ..._set(asset, accessor, newValue) };
          }
          return asset;
        })
      );
    };

    const getEditInput = (
      { value, row: { index }, column: { id }, onSaveEdit },
      altOnChange
    ) => {
      const onKeyPress = (event) => {
        if (event.key === "Enter") {
          onSaveEdit();
        }
      };

      return (
        <Input
          placeholder="Value"
          key={`${id}-${index}`}
          value={value}
          onChange={
            altOnChange
              ? (newValue) => altOnChange(newValue, index)
              : (newValue) => onValueChange(newValue, index, id)
          }
          onKeyPress={onKeyPress}
        />
      );
    };

    const columns = [
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ value }) => <ImageCell src={value} circled />,
        Edit: ({ value }) => <ImageCell src={value} circled />,
        width: TABLE_COLUMN_WIDTH,
        minWidth: 90,
        maxWidth: TABLE_COLUMN_WIDTH,
        isPrimary: true,
        disableSortBy: true,
        disableResizing: true,
        disableFilters: true,
        Aggregated: () => null,
      },
      {
        Header: "Name",
        accessor: "name",
        isPrimary: true,
        Edit: (props) => getEditInput(props),
        width: 200,
      },
      {
        Header: "Category",
        accessor: "category",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
          nestedColumnId: "subcategory",
        },
      },
      {
        Header: "Subcategory",
        accessor: "subcategory",
        width: 200,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
          getNestedColumnOptions: (selectedCategoryValue) => {
            // is list of values
            if (Array.isArray(selectedCategoryValue)) {
              const list = selectedCategoryValue.reduce((acc, item) => {
                const subCatList = subCatOptionsMap[item];
                return [
                  ...acc,
                  ...subCatList.map((l) => ({
                    label: l.label,
                    value: l.label,
                  })),
                ];
              }, []);
              return list;
            }

            if (selectedCategoryValue) {
              // if single string
              const subCatList = subCatOptionsMap[selectedCategoryValue];
              return subCatList.map((l) => ({
                label: l.label,
                value: l.label,
              }));
            }

            return [];
          },
        },
      },
      {
        Header: "Owned By",
        accessor: "ownedBy",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Brand",
        accessor: "brand",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        aggregate: "sum",
        Aggregated: ({ row }) => {
          return <p>{Math.trunc(aggregateTableRow(row, "quantity"))}</p>;
        },
      },
      {
        Header: "Condition",
        accessor: "condition",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Year Made",
        accessor: "yearMade",
        Cell: ({ value }) => <DateCell value={value} />,
        filterOptions: {
          label: "Manufacture Date",
          filterType: FILTER_TYPES.date,
        },
        width: 350,
      },
      {
        Header: "Place of Origin",
        accessor: "origin",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        width: 200,
      },
      {
        Header: "Buy It Again",
        accessor: "link",
        Edit: (props) =>
          getEditInput(props, (newValue, index) => {
            setAssets((prevAssets) =>
              prevAssets.map((asset, indexToEdit) => {
                if (index === indexToEdit) {
                  const attribute = selectedMeasurements.find(
                    (x) => x.detail === "Buy It Again"
                  );
                  const measurement = asset?.additionalInfo.find(
                    (el) => el?.id === attribute.id
                  );
                  return {
                    ...asset,
                    measurement: [
                      ...measurement,
                      { id: attribute.id, value: newValue },
                    ],
                  };
                }
                return asset;
              })
            );
          }),
      },
      {
        Header: "# of Owners",
        accessor: "numberOfOwners",
        aggregate: "sum",
        Aggregated: ({ row }) => {
          return <p>{Math.trunc(aggregateTableRow(row, "numberOfOwners"))}</p>;
        },
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => {
          return (
            <TagList
              tags={row?.original?.currentTags}
              tagClassName="py-1 px-2"
              className="gap-2"
              showCount
            />
          );
        },
        minWidth: 200,
        filterOptions: {
          label: "Tags",
          filterType: FILTER_TYPES.tags,
        },
      },
      {
        Header: "Purchase Date",
        accessor: "purchaseDate",
        Cell: ({ value }) => <DateCell value={value} />,
        filterOptions: {
          label: "Purchase Date",
          filterType: FILTER_TYPES.date,
        },
        width: 350,
      },
      {
        Header: "Date Modified",
        accessor: "lastUpdated",
        Cell: ({ value }) => <DateCell value={value} />,
        width: 350,
        Aggregated: () => null,
      },
      {
        Header: "Par level",
        accessor: "parLevel",
      },
      {
        Header: "Association",
        accessor: "association",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Property",
        accessor: "property",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        show: false,
      },
      {
        Header: "Project",
        accessor: "project",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        show: false,
      },
      {
        Header: "Spaces",
        accessor: "location",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        show: false,
      },
      {
        Header: "Has Links",
        accessor: "hasLinks",
        Cell: ({ value }) => <BooleanBullet className={bulletCN(value)} />,
        filterOptions: {
          filterType: FILTER_TYPES.boolean,
        },
        show: false,
      },
      {
        Header: "Has Documents",
        accessor: "hasDocuments",
        Cell: ({ value }) => <BooleanBullet className={bulletCN(value)} />,
        filterOptions: {
          filterType: FILTER_TYPES.boolean,
        },
        show: false,
      },
      {
        Header: "Appraised",
        accessor: "isAppraised",
        Cell: ({ value }) => <BooleanBullet className={bulletCN(value)} />,
        filterOptions: {
          filterType: FILTER_TYPES.boolean,
        },
        show: false,
      },
      {
        Header: "Appraisal Date",
        accessor: "appraisalDate",
        Cell: ({ value }) => <DateCell value={value} />,
        filterOptions: {
          label: "Appraisal Date",
          filterType: FILTER_TYPES.date,
        },
        width: 350,
        show: false,
      },
      {
        Header: "Insurance Agent",
        accessor: "insuranceAgent",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        show: false,
      },
      {
        Header: "Insurance Broker",
        accessor: "insuranceBroker",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        show: false,
      },
    ];

    additionalDetailsColumnNames.map((detail) => {
      if (columns.some((col) => col.header === detail)) {
        columns.push({
          Header: detail,
          accessor: detail,
          filterOptions: {
            label: `Add. Info (${detail})`,
            filterType: FILTER_TYPES.isOrNot,
          },
          show: false,
        });
      }
      return detail;
    });
    return columns;
  }, [
    additionalDetailsColumnNames,
    setAssets,
    subCatOptionsMap,
    selectedMeasurements,
  ]);

  return (
    <Widget
      title={null}
      draggable={false}
      childClassName={containerCN}
      loading={isLoading}
      overflow
      backToTop
    >
      <Table
        addNestedRoute="/assets"
        resourceName={name}
        className={className}
        columns={tableColumns}
        cardGrid={!isShowingAssetsTable}
        cardGridComponent={(rows) => {
          return (
            <CardGrid resourceName="Asset" rows={rows} onClick={onRowClick} />
          );
        }}
        data={data}
        onRowClick={({ index }) => onRowClick(data[index]?.id)}
        onSaveRowClick={({ index }) => onSaveRowClick(index)}
        onEditRowCancelClick={onEditRowCancelClick}
        onDeleteRowClick={({ index }) => onDeleteRowClick(data[index]?.id)}
        onStartRowEditing={onStartRowEditing}
        onEndRowEditing={onEndRowEditing}
        allowSelection={allowSelection}
        inLineForm={(row, isEdit, togglePopOver) => (
          <AssetTableInLineForm
            row={row}
            togglePopOver={togglePopOver}
            isEdit={isEdit}
            assetCategories={categoryOptionsList}
            assetSubcategories={subCatOptionsMap}
            propertyRef={propertyRef}
            onEditSaveCallback={(asset) => onEditSaveCallback(row, asset)}
            onAddSaveCallback={(asset) => onAddSaveCallback(row, asset)}
            categoryOptions={categoryOptions}
            prefillFieldsOnAddAnother={[
              "category",
              "categoryId",
              "subcategory",
              "subcategoryId",
            ]}
          />
        )}
        groupOptions={groupOptions}
        templateSettings={templateSettings}
        updateUserTemplateSettings={updateUserTemplateSettings}
        deleteUserTemplateSettings={deleteUserTemplateSettings}
        showConstantRowIndex
        showColumnSettingsLeft={showColumnSettingsLeft}
        fileActionsIcons={fileActionsIcons}
        onSelectedRowChange={handleRowSelect}
        hideSiteHeaderTitle={false}
        hideSiteHeaderActionButtons={false}
      />
    </Widget>
  );
};

PureAssetTable.propTypes = {
  allowSelection: PropTypes.bool,
  /**
   * classNames to pass to the Table
   */
  className: PropTypes.string,
  /**
   * data to pass to the table
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      category: PropTypes.string,
      subcategory: PropTypes.string,
      type: PropTypes.string,
      link: PropTypes.string,
      valuation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  /**
   * unique name for the table
   */
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  setAssets: PropTypes.func,
  onRowClick: PropTypes.func,
  onSaveRowClick: PropTypes.func,
  onEditRowCancelClick: PropTypes.func,
  onDeleteRowClick: PropTypes.func,
  onStartRowEditing: PropTypes.func,
  onEndRowEditing: PropTypes.func,
  onEditSaveCallback: PropTypes.func,
  onAddSaveCallback: PropTypes.func,
  propertyRef: PropTypes.string,
  templateSettings: PropTypes.arrayOf(PropTypes.shape({})),
  updateUserTemplateSettings: PropTypes.func,
  deleteUserTemplateSettings: PropTypes.func,
  /**
   * array of the possible categories for an asset, formatted for the DDL
   */
  // eslint-disable-next-line react/forbid-prop-types
  categoryOptionsList: PropTypes.arrayOf(PropTypes.object),
  /**
   * object mapping category codes to the array of possible subcategories, formatted for the DDL
   */
  subCatOptionsMap: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
  /**
   * Columns names for Asset additional details for the purpose of creating filters
   */
  additionalDetailsColumnNames: PropTypes.arrayOf(PropTypes.string),
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          category: PropTypes.string,
        })
      ),
    })
  ),
  handleRowSelect: PropTypes.func,
  showColumnSettingsLeft: PropTypes.bool,
  fileActionsIcons: PropTypes.element,
};

PureAssetTable.defaultProps = {
  allowSelection: false,
  className: undefined,
  data: undefined,
  name: undefined,
  isLoading: false,
  setAssets: undefined,
  onRowClick: undefined,
  onSaveRowClick: undefined,
  onEditRowCancelClick: undefined,
  onDeleteRowClick: undefined,
  onStartRowEditing: undefined,
  onEndRowEditing: undefined,
  onEditSaveCallback: undefined,
  onAddSaveCallback: undefined,
  propertyRef: undefined,
  templateSettings: [],
  updateUserTemplateSettings: undefined,
  deleteUserTemplateSettings: undefined,
  categoryOptionsList: [],
  subCatOptionsMap: {},
  categoryOptions: [],
  additionalDetailsColumnNames: [],
  handleRowSelect: () => {},
  showColumnSettingsLeft: false,
  fileActionsIcons: undefined,
};

export default PureAssetTable;
