import useRemoveFromCalendar from "../../hooks/api/calendar/useRemoveFromCalendar";
import { useRemoveFromTaskList } from "../../hooks/api/tasks";

const useRemoveTaskFromCache = () => {
  const { deleteFromTaskList } = useRemoveFromTaskList();
  const { removeFromCalendar } = useRemoveFromCalendar();

  const removeTasksFromCache = (taskIds) => {
    taskIds?.forEach((id) => {
      if (!id) return;

      const paramsForRemoval = { args: { id }, operation: "$all" };
      // False - declares no post to BE, only mutate cache
      deleteFromTaskList(paramsForRemoval, false);
      removeFromCalendar(paramsForRemoval, false);
    });
  };

  return { removeTasksFromCache };
};

export default useRemoveTaskFromCache;
