import { useCallback, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { useQueryClient } from "react-query";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Auth from "../../helpers/Auth";
import useSubscription from "../useSubscription";
import { useGetTenants, useUpdateTenantName } from "../useTenants";
import useSubscriptionFormReducer from "../useSubscriptionFormReducer";
import {
  formatBillingContactInfo,
  formatPaymentInfo,
  formatPlanSelectionInfo,
} from "../../helpers/Formatters";
import SignUpAPI from "../../Pages/SignUp/SignUpAPI";
import { toastError, toastMessage } from "../../stories/Components/Toast/Toast";
import { subscriptionKeys } from "../../config/reactQuery/queryKeyFactory";
import { useMembers } from "../useUsers.new";
import {
  billingAddressIsValid,
  billingCardIsValid,
  billingContactInfoIsValid,
} from "../../helpers/FormValidations";

const useBillingForm = () => {
  const { data: originalSubscription, isLoading } = useSubscription();
  const [subscription, dispatchBilling] = useSubscriptionFormReducer();
  const stripe = useStripe();
  const { data: tenants } = useGetTenants();
  const currentTenantId = Auth.getTenant();
  const currentTenant = tenants?.find(
    (item) => item?.value === currentTenantId
  );
  const { mutateAsync: updateTenantName } = useUpdateTenantName();
  const queryClient = useQueryClient();
  const elements = useElements();
  const [isLoadingBilling, setIsLoadingBilling] = useState(false);
  const { data: members } = useMembers("member");
  const [activeMemberCount, setActiveMemberCount] = useState();

  useEffect(() => {
    let count = 0;
    if (members?.length) {
      members?.forEach((member) => {
        if (member?.active) {
          count += 1;
        }
      });
    }
    setActiveMemberCount(count);
  }, [members]);

  const cardNumber = elements?.getElement(CardNumberElement);
  if (cardNumber)
    cardNumber.on("change", (f) => {
      dispatchBilling({ value: f?.complete, type: "cardNumber" });
      dispatchBilling({ type: "cardElement", value: f });
    });

  const cardexpiry = elements?.getElement(CardExpiryElement);
  if (cardexpiry)
    cardexpiry.on("change", (f) => {
      dispatchBilling({ value: f?.complete, type: "expiration" });
    });

  const cardcvc = elements?.getElement(CardCvcElement);
  if (cardcvc)
    cardcvc.on("change", (f) => {
      dispatchBilling({ value: f?.complete, type: "cvc" });
    });

  /**
   * returns true if change detected in billing contact info
   */
  const original = {
    ...originalSubscription,
    contactWorkspaceName: currentTenant?.label,
  };
  const billingContactInfoChange = !isEqual(
    formatBillingContactInfo(original),
    formatBillingContactInfo(subscription)
  );

  /**
   * returns true if change detected in billing plan selection info
   */
  const billingPlanSelectionChange = !isEqual(
    formatPlanSelectionInfo(originalSubscription),
    formatPlanSelectionInfo(subscription)
  );

  /**
   * returns true if change detected in billing Information info
   */
  const billingPaymentChange = !isEqual(
    formatPaymentInfo(originalSubscription),
    formatPaymentInfo(subscription)
  );

  // reset form reducer to state source of truth
  const resetBillingForm = useCallback(() => {
    const subscriptionAndWorkspace = {
      ...originalSubscription,
      contactWorkspaceName: currentTenant?.label,
      activeUserCount: originalSubscription?.userCount,
    };
    dispatchBilling({
      type: "reset",
      subscription: subscriptionAndWorkspace,
    });
  }, [currentTenant?.label, dispatchBilling, originalSubscription]);

  // load in original subscription data
  useEffect(() => {
    if (!isLoading) {
      resetBillingForm();
    }
  }, [resetBillingForm, isLoading]);

  const saveBilling = useCallback(async () => {
    try {
      setIsLoadingBilling(true);
      if (billingContactInfoChange) {
        const body = {
          name: `${subscription.contactFirstName} ${subscription.contactLastName}`,
          metadata: {
            title: subscription.contactTitle,
            company: subscription.contactCompany,
          },
          email: subscription.contactEmail,
          phone: subscription.contactPhone,
        };

        await SignUpAPI.updateCustomer(subscription?.customerId, body);

        if (currentTenant?.label !== subscription?.contactWorkspaceName) {
          await updateTenantName({
            id: currentTenant?.value,
            updatedName: subscription?.contactWorkspaceName,
          });
        }
      }
      if (billingPlanSelectionChange) {
        const isMonthly = subscription?.billingCycle === "monthly";

        await SignUpAPI.updateSubscriptionItem(subscription.subscriptionItem, {
          quantity: subscription.userCount,
          price: isMonthly
            ? subscription.planSelection.priceIdMonthly
            : subscription.planSelection.priceIdAnnual,
        });
      }

      if (billingPaymentChange) {
        const address = {
          line1: subscription.billingStreet,
          line2: subscription.billingStreet2,
          city: subscription.billingCity,
          state: subscription.billingState,
          country: subscription.billingCountry?.label,
          postal_code: subscription.billingZipCode || "00000",
        };
        await SignUpAPI.updateCustomer(subscription?.customerId, { address });

        if (subscription?.cardElement) {
          const cardNumberElement = elements.getElement(CardNumberElement);
          await stripe
            .createPaymentMethod({
              type: "card",
              card: cardNumberElement, // needs to be stripe card element with card info
              billing_details: {
                name: subscription.nameOnCard,
                address: {
                  ...address,
                  country: subscription?.countryCode,
                },
                email: subscription.contactEmail,
                phone: subscription.contactPhone,
              },
            })
            .then((res) => {
              if (res?.paymentMethod?.id) {
                SignUpAPI.updatePaymentCard(
                  subscription?.customerId,
                  res?.paymentMethod?.id
                );
              }
            });
        }
      }
    } catch (e) {
      resetBillingForm();
      console.warn("Error Saving Billing", e);
      toastError("Error while saving billing information.");
    } finally {
      queryClient.invalidateQueries(subscriptionKeys.subscription);
      toastMessage("Billing updated successfully");
      setIsLoadingBilling(false);
    }
  }, [
    billingContactInfoChange,
    billingPlanSelectionChange,
    billingPaymentChange,
    subscription,
    currentTenant?.label,
    currentTenant?.value,
    updateTenantName,
    elements,
    stripe,
    resetBillingForm,
    queryClient,
  ]);

  // plan selection widget validation
  const planInfoIsValid = useMemo(() => {
    const selectedPlanMinUsers = subscription?.planSelection?.minUsers;
    const selectedPlanMaxUsers = subscription?.planSelection?.maxUsers;
    // userCount must fall inside the selected plan min and max, and be equal to or above the active user count
    return (
      subscription?.userCount <= selectedPlanMaxUsers &&
      subscription?.userCount >= selectedPlanMinUsers &&
      subscription?.userCount >= activeMemberCount
    );
  }, [activeMemberCount, subscription]);

  // contact info widget validation
  const contactInfoIsValid = billingContactInfoIsValid(subscription);

  // billing information address validation
  const billingAddressValid = billingAddressIsValid(subscription);

  // billing info widget validation
  const billingInfoIsValid =
    subscription?.cardElement || // if card is updated, check card fields otherwise skip
    subscription?.nameOnCard !== originalSubscription?.nameOnCard // if the name on card changes
      ? billingCardIsValid(subscription) && billingAddressValid
      : billingAddressValid;

  const anyChanges =
    billingContactInfoChange ||
    billingPlanSelectionChange ||
    billingPaymentChange ||
    subscription?.cardElement; // cardElement present if card details have changed

  /**
   * Returns true if valid changes, false if no changes or invalid changes
   * To disable/enable save button on widgetContainer
   */
  const canSaveBilling =
    anyChanges && contactInfoIsValid && planInfoIsValid && billingInfoIsValid;

  return {
    billingFormData: subscription,
    saveBilling,
    originalSubscription,
    dispatchBilling,
    isLoadingBilling,
    resetBillingForm,
    canSaveBilling,
    subscriptionDeactivated: !!subscription?.toCancelAtPeriodEnd,
  };
};

export default useBillingForm;
