import { SubmittalAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { useAppState } from "../state/appState";
import { submittalKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";
import useCurrentUser from "./useCurrentUser";
import { useProjectsOverview } from "./projects";
import {
  SET_SUBMITTALS,
  SET_SUBMITTAL_DD,
  SET_SUBMITTAL_DICT,
} from "../constants";

const getSubmittals = async (params) => {
  const submittalDict = {};
  const submittalDD = [];

  const { data } = await SubmittalAPI.get({
    params: {
      association: params?.project,
    },
  });

  const submittals = data?.entries?.map(({ resource }) => {
    submittalDict[resource?.reference] = {
      ...resource,
    };

    submittalDD.push({
      label: resource?.name,
      value: resource?.reference,
      id: resource?.id,
      isTemplate: resource?.isTemplate,
      isDefaultTemplate: resource?.isDefaultTemplate,
    });

    return resource;
  });

  return { submittals, submittalDD, submittalDict };
};

const postSubmittal = async (submittal) => {
  const { data } = await SubmittalAPI.post(submittal);
  return data;
};

const deleteSubmittals = async (ids) => {
  await Promise.all(
    ids.map(async (id) => {
      await SubmittalAPI.delete(id);
      return id;
    })
  );
};

/**
 * Fetch all submittals or by projects
 * @param {Object} params object that holds the association reference Ex: { project: Project/1234-5678-0000}
 * @returns
 */
export const useSubmittals = (params) => {
  const [{ userDict }, dispatch] = useAppState();
  const { data: currentUser } = useCurrentUser();

  const { projectDict } = useProjectsOverview();

  // Get simple or compund query key based on params
  const queryKey = params?.project
    ? submittalKeys.submittalsByAssociation(params?.project)
    : submittalKeys.submittals;

  // Enabled query if there is an user signed in
  // AND If projects and users are loaded
  const enabled =
    !!currentUser?.permissions?.submittal?.can_see &&
    !!projectDict &&
    !!userDict;

  return useAuthenticatedQuery({
    queryKey,
    queryFn: () => getSubmittals(params),
    enabled,
    placeholderData: {
      submittals: [],
      submittalDict: {},
      submittalDD: [],
    },
    onSuccess: ({ submittalDD, submittals, submittalDict }) => {
      dispatch({
        type: SET_SUBMITTAL_DD,
        submittalDD,
      });

      dispatch({
        type: SET_SUBMITTALS,
        submittals,
      });
      dispatch({
        type: SET_SUBMITTAL_DICT,
        submittalDict,
      });
    },
    onError: (error) => {
      console.error("useSubmittals", error);
    },
  });
};

/**
 * Mutation hook to post new submittals
 * @param {string} mutationKey key to track mutation (optional)
 */
export const usePostSubmittal = (mutationKey) => {
  const [{ submittals }, dispatch] = useAppState();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: postSubmittal,
    onSuccess: (newSubmittal) => {
      queryClient.getQueryData(submittalKeys.submittals, (current) => ({
        ...current,
        submittals: [...(current?.submittals ?? [], newSubmittal)],
        submittalDict: {
          ...current?.submittalDict,
          [newSubmittal.reference]: newSubmittal,
        },
        submittalDD: [
          ...(current?.submittalDD ?? []),
          {
            id: newSubmittal?.id,
            isTemplate: newSubmittal?.isTemplate,
            isDefaultTemplate: newSubmittal?.isDefaultTemplate,
          },
        ],
      }));

      dispatch({
        type: SET_SUBMITTALS,
        submittals: [...submittals, newSubmittal],
      });

      toastMessage("Submittal was created successfully");
    },
    onError: (error) => {
      toastError("Error creating submittal");
      console.error("usePostSubmittal", error);
    },
    onSettled: () => {
      queryClient.invalidateQueries(submittalKeys.submittals);
    },
  });
};

/**
 * Mutation hook to delete submittals
 * @param {string} mutationKey key to track mutation (optional)
 */
export const useDeleteSubmittals = (mutationKey) => {
  const [{ submittals }, dispatch] = useAppState();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: deleteSubmittals,
    onMutate: async (ids) => {
      const queryKey = submittalKeys.submittals;
      await queryClient.cancelQueries(queryKey);

      const currentSubmittalCache = queryClient.getQueryData(queryKey);

      const result = submittals?.filter((item) => !ids.includes(item.id));

      queryClient.setQueryData((current) => ({
        ...current,
        submittals: result,
      }));

      dispatch({
        type: SET_SUBMITTALS,
        submittals: result,
      });

      return { queryKey, currentSubmittalCache };
    },
    // TODO (Uncomment those lines when backend fix issue: https://estatespaceco.slack.com/archives/C01E5VBBGTH/p1694714670500119
    // onError: (error, _, { queryKey, currentSubmittalCache }) => {
    onError: (error) => {
      // Rollback data in case of any error
      // queryClient.setQueryData(queryKey, currentSubmittalCache);

      // toastError("There was an issue removing submittals");
      console.error("useDeleteSubmittal", error);
    },
    onSettled: () => {
      // If success or error, we gonna pull fresh data
      queryClient.invalidateQueries(submittalKeys.submittals);
    },
  });
};
