import { fetchProperties } from "../../helpers/Properties";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import propertyKeys from "./propertiesKeys";

/**
 * Query hook to fetch properties
 */
const useProperties = ({ select } = {}) => {
  return useAuthenticatedQuery({
    queryKey: propertyKeys.all,
    select,
    queryFn: ({ signal }) => fetchProperties(signal),
    onError: (error) => {
      console.error("useProperties: Error fetching properties", error);
    },
  });
};

export default useProperties;
