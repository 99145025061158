import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isEqual, isEmpty } from "lodash";
import useManagementConfiguration from "../../../../hooks/useManagementConfiguration";
import useManagementConfigurationReducer from "../../../../hooks/useManagementConfigurationReducer";
import useWorkflowsConfigurationReducer from "../../../../hooks/useWorkflowsConfigurationReducer";
import useWorkflowsConfiguration from "../../../../hooks/useWorkflowsConfiguration";
import { resolveFileRef } from "../../../../helpers/File";
import useDocumentsConfiguration from "../../../../hooks/useDocumentsConfiguration";
import useDocumentsConfigurationReducer from "../../../../hooks/useDocumentsConfigurationReducer";
import useSystemConfiguration from "../../../../hooks/useSystemConfiguration";
import useSystemConfigurationReducer from "../../../../hooks/useSystemConfigurationReducer";
import useFinancialsConfigurationReducer from "../../../../hooks/useFinancialsConfigurationReducer";
import useFinancialsConfiguration from "../../../../hooks/useFinancialsConfiguration";
import {
  formatDocumentConfigurationOriginalPayload,
  formatFinancialConfigurationOriginalPayload,
  formatManagementConfigurationOriginalPayload,
  formatSystemConfigurationOriginalPayload,
  formatWorkflowConfigurationOriginalPayload,
} from "../../../../helpers/Settings";
import useConfigurationLastUpdated from "../../../../hooks/useConfigurationLastUpdated";
import useAttributes from "../../../../hooks/useAttributes";
import useAttributesFormReducer from "../../../../hooks/useAttributesFormReducer";
import updateAttributes from "../../../../helpers/Attribute";
import useConfigurationPatch from "../../../../hooks/useConfigurationPatch";
import {
  useCreateTag,
  useGetTags,
  useRemoveTags,
  useUpdateTag,
} from "../../../../hooks/useTags";
import useTagsFormReducer from "../../../../hooks/useTagsFormReducer";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import {
  documentSettingsSchema,
  financialSettingsSchema,
  managementSettingsSchema,
  systemSettingsSchema,
  workflowSettingsSchema,
} from "../../../../helpers/FormValidations";
import { toastError } from "../../Toast/Toast";
import useBillingForm from "../../../../hooks/BillingForm/useBillingForm";

const useAccountAndAdminContent = ({
  currentIndex,
  isSaving,
  setIsSaving,
  setIsCancel,
  isCancel,
  isEditing,
  setIsEditing,
}) => {
  const onUpdateTags = useRef();
  const {
    data: managementConfiguration,
    isLoading: isLoadingManagementConfig,
  } = useManagementConfiguration();
  const { data: workflowsConfiguration, isLoading: isLoadingWFConfig } =
    useWorkflowsConfiguration();
  const { data: documentsConfiguration, isLoading: isLoadingDocumentConfig } =
    useDocumentsConfiguration();
  const { data: systemConfiguration, isLoading: isLoadingSystemConfig } =
    useSystemConfiguration();
  const {
    data: financialsConfiguration,
    isLoading: isLoadingFinancialsConfig,
  } = useFinancialsConfiguration();
  const [measurements, reloadMeasurements] = useAttributes();

  const { data: tagsData } = useGetTags();
  const addTag = useCreateTag();
  const removeTags = useRemoveTags();
  const updateTag = useUpdateTag();

  // Hook to handle billing tab formState
  const {
    billingFormData,
    saveBilling,
    dispatchBilling,
    isLoadingBilling,
    resetBillingForm,
    canSaveBilling,
    subscriptionDeactivated,
  } = useBillingForm();

  // tags
  const [tagsSettings, dispatchTagsSettings] = useTagsFormReducer();
  // attributes
  const [attributeSettings, dispatchAttributeSettings] =
    useAttributesFormReducer();
  // financials
  const [financialsSettings, dispatchFinancialsSettings] =
    useFinancialsConfigurationReducer();
  // management
  const [managementSettings, dispatchManagementSettings] =
    useManagementConfigurationReducer();
  // workflows
  const [workflowsSettings, dispatchWorkflowsSettings] =
    useWorkflowsConfigurationReducer();
  // documents
  const [documentsSettings, dispatchDocumentsSettings] =
    useDocumentsConfigurationReducer();
  // system
  const [systemSettings, dispatchSystemSettings] =
    useSystemConfigurationReducer();

  const { mutate, isLoading } = useConfigurationPatch();

  const isLoadingData =
    isLoadingDocumentConfig ||
    isLoadingDocumentConfig ||
    isLoadingFinancialsConfig ||
    isLoadingManagementConfig ||
    isLoadingSystemConfig ||
    isLoadingWFConfig ||
    isEmpty(billingFormData) ||
    isLoading;

  const [configurationLastUpdated] = useConfigurationLastUpdated();

  const [settingDetails, setSettingDetails] = useState({});
  const [noChangesMade, setNoChangesMade] = useState(true);
  const [isValid, setIsValid] = useState(true);

  const correctMeasurements = useMemo(() => {
    let formattedMeasurements = [];
    if (measurements?.length) {
      formattedMeasurements = measurements?.map((item) => {
        if (item.options.length) {
          return {
            ...item,
            options: item.options.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
          };
        }
        if (item.unit?.options.length) {
          return {
            ...item,
            fieldType: "number-entry-with-unit",
            unit: {
              ...item.unit,
              options: item.unit.options.map(({ id, name }) => ({
                label: name,
                value: id,
              })),
            },
          };
        }
        return item;
      });
    }
    return formattedMeasurements;
  }, [measurements]);

  const setEmployeeHandbook = useCallback(
    async (ref) => {
      if (ref) {
        const file = await resolveFileRef(ref);
        dispatchWorkflowsSettings({
          type: "changeHandbookFileName",
          value: file?.name,
        });
      }
    },
    [dispatchWorkflowsSettings]
  );

  const resetTagsSettings = useCallback(() => {
    dispatchTagsSettings({
      type: "reset",
      tags: tagsData?.tags,
    });
  }, [dispatchTagsSettings, tagsData]);

  const resetAttributeSettings = useCallback(() => {
    dispatchAttributeSettings({
      type: "reset",
      attributes: correctMeasurements,
    });
  }, [dispatchAttributeSettings, correctMeasurements]);

  const resetDocumentsSettings = useCallback(() => {
    dispatchDocumentsSettings({
      type: "reset",
      configuration: formatDocumentConfigurationOriginalPayload(
        documentsConfiguration
      ),
    });
  }, [dispatchDocumentsSettings, documentsConfiguration]);

  const resetFinancialsSettings = useCallback(() => {
    dispatchFinancialsSettings({
      type: "reset",
      configuration: formatFinancialConfigurationOriginalPayload(
        financialsConfiguration
      ),
    });
  }, [dispatchFinancialsSettings, financialsConfiguration]);

  const resetManagementSettings = useCallback(() => {
    dispatchManagementSettings({
      type: "reset",
      configuration: formatManagementConfigurationOriginalPayload(
        managementConfiguration
      ),
    });
  }, [dispatchManagementSettings, managementConfiguration]);

  const resetSystemSettings = useCallback(() => {
    dispatchSystemSettings({
      type: "reset",
      configuration:
        formatSystemConfigurationOriginalPayload(systemConfiguration),
    });
  }, [dispatchSystemSettings, systemConfiguration]);

  const resetWorkflowsSettings = useCallback(() => {
    const employeeHandbookRef =
      workflowsConfiguration.workflows?.employeeHandbookAcceptance?.document;

    dispatchWorkflowsSettings({
      type: "reset",
      configuration: formatWorkflowConfigurationOriginalPayload(
        workflowsConfiguration,
        employeeHandbookRef
      ),
    });

    setEmployeeHandbook(employeeHandbookRef);
  }, [dispatchWorkflowsSettings, setEmployeeHandbook, workflowsConfiguration]);

  useEffect(() => {
    setIsSaving?.(isLoading);
  }, [isLoading, setIsSaving]);

  useEffect(() => {
    if (isCancel) {
      resetDocumentsSettings();
      resetFinancialsSettings();
      resetManagementSettings();
      resetSystemSettings();
      resetWorkflowsSettings();
      resetAttributeSettings();
      resetTagsSettings();
      resetBillingForm();
      setIsCancel(false);
    }
  }, [
    isCancel,
    resetDocumentsSettings,
    resetFinancialsSettings,
    resetManagementSettings,
    resetSystemSettings,
    resetWorkflowsSettings,
    resetAttributeSettings,
    resetTagsSettings,
    setIsCancel,
    resetBillingForm,
  ]);

  useEffect(() => {
    if (!isEmpty(measurements)) {
      resetAttributeSettings();
    }
  }, [measurements, resetAttributeSettings]);

  useEffect(() => {
    if (!isEmpty(financialsConfiguration)) {
      resetFinancialsSettings();
    }
  }, [financialsConfiguration, resetFinancialsSettings]);

  useEffect(() => {
    if (!isEmpty(systemConfiguration)) {
      resetSystemSettings();
    }
  }, [resetSystemSettings, systemConfiguration]);

  useEffect(() => {
    if (!isEmpty(documentsConfiguration)) {
      resetDocumentsSettings();
    }
  }, [documentsConfiguration, resetDocumentsSettings]);

  useEffect(() => {
    if (!isEmpty(workflowsConfiguration)) {
      resetWorkflowsSettings();
    }
  }, [resetWorkflowsSettings, workflowsConfiguration]);

  useEffect(() => {
    if (!isEmpty(managementConfiguration)) {
      resetManagementSettings();
    }
  }, [managementConfiguration, resetManagementSettings]);

  useEffect(() => {
    if (!isEmpty(tagsData?.tags)) {
      resetTagsSettings();
    }
  }, [resetTagsSettings, tagsData?.tags]);

  const onUpdateAttributes = useCallback(async () => {
    const { errors } = await updateAttributes(attributeSettings, measurements);

    errors.forEach(() => {
      toastError("Error Updating Attributes");
    });
  }, [attributeSettings, measurements]);

  onUpdateTags.current = () => {
    // post
    const newTags = tagsSettings.filter((t) => t.wasAdded);
    newTags.forEach((t) => {
      addTag.mutate(t);
    });

    // delete
    const deletedTags = tagsSettings
      .filter((t) => t.wasRemoved && !t.wasAdded)
      .map((t) => t.reference);
    removeTags.mutate(deletedTags);

    // update
    const updatedTags = tagsSettings.filter((t) => t.wasUpdated && !t.wasAdded);

    Promise.all(
      updatedTags.map(async (t) => {
        const data = await updateTag.mutateAsync({
          id: t.id,
          prevTag: t,
          updatedTag: {
            ...t,
            label: t.currentLabel,
          },
        });

        return data;
      })
    );
  };

  const updateAllConfigurations = useCallback(() => {
    onUpdateAttributes();
    onUpdateTags?.current();

    mutate({
      patchDate: configurationLastUpdated,
      managementConfiguration,
      managementSettings,
      systemConfiguration,
      systemSettings,
      financialsConfiguration,
      financialsSettings,
      documentsConfiguration,
      documentsSettings,
      workflowsConfiguration,
      workflowsSettings,
      // Aditional functions to reload mesurements and wf templates
      reloadMeasurements,
    });
  }, [
    configurationLastUpdated,
    documentsConfiguration,
    documentsSettings,
    financialsConfiguration,
    financialsSettings,
    managementConfiguration,
    managementSettings,
    mutate,
    onUpdateAttributes,
    reloadMeasurements,
    systemConfiguration,
    systemSettings,
    workflowsConfiguration,
    workflowsSettings,
  ]);

  const widgetContainerSave = async () => {
    // TODO: Move other saves here with switch case when refactoring to use edit/save via widget container
    // Await needed here, as multiple API calls are being made inside of saveBilling
    await saveBilling();
  };

  useEffect(() => {
    if (isSaving) {
      updateAllConfigurations();
    }
  }, [isSaving, updateAllConfigurations]);

  // Changes & validation check
  useDidMountEffect(() => {
    const isNoChanges =
      isEqual(
        systemSettings,
        formatSystemConfigurationOriginalPayload(systemConfiguration)
      ) &&
      isEqual(
        managementSettings,
        formatManagementConfigurationOriginalPayload(managementConfiguration)
      ) &&
      isEqual(
        workflowsSettings,
        formatWorkflowConfigurationOriginalPayload(workflowsConfiguration)
      ) &&
      isEqual(
        documentsSettings,
        formatDocumentConfigurationOriginalPayload(documentsConfiguration)
      ) &&
      isEqual(
        financialsSettings,
        formatFinancialConfigurationOriginalPayload(financialsConfiguration)
      ) &&
      isEqual(attributeSettings, correctMeasurements) &&
      isEqual(
        tagsSettings,
        tagsData?.tags?.map((tag) => ({
          ...tag,
          currentLabel: tag?.label,
        }))
      );
    setNoChangesMade(isNoChanges);

    const validationFields = [];
    validationFields.push(systemSettingsSchema.isValid(systemSettings));
    validationFields.push(managementSettingsSchema.isValid(managementSettings));
    validationFields.push(financialSettingsSchema.isValid(financialsSettings));
    validationFields.push(documentSettingsSchema.isValid(documentsSettings));
    validationFields.push(workflowSettingsSchema.isValid(workflowsSettings));
    Promise.all(validationFields).then((values) => {
      setIsValid(!values.some((field) => field === false));
    });
  }, [
    systemSettings,
    managementSettings,
    workflowsSettings,
    documentsSettings,
    financialsSettings,
    attributeSettings,
    tagsSettings,
  ]);

  const navigateToHolidaysSection = useCallback(() => {
    setSettingDetails({
      id: "holidays",
      title: "Holidays",
      type: "System",
      description: "Select the holidays and add custom holidays in EstateSpace",
      customBackNavigation: {
        id: "rateSheet",
        title: "Rate Sheet",
        type: "Financial",
        description:
          "Manage the default rate sheet for projects in EstateSpace",
      },
    });
  }, []);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  return {
    handleEditClick,
    navigateToHolidaysSection,
    settingDetails,
    setSettingDetails,
    setIsValid,
    isValid,
    noChangesMade,
    currentIndex,
    isLoadingData,
    managementSettings,
    dispatchManagementSettings,
    attributeSettings,
    dispatchAttributeSettings,
    systemSettings,
    dispatchSystemSettings,
    financialsSettings,
    dispatchFinancialsSettings,
    documentsSettings,
    dispatchDocumentsSettings,
    tagsSettings,
    dispatchTagsSettings,
    resetSystemSettings,
    widgetContainerSave,
    dispatchBilling,
    billingFormData,
    isLoadingBilling,
    canSaveBilling,
    subscriptionDeactivated,
  };
};

export default useAccountAndAdminContent;
