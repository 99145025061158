import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import Dropdown from "../Dropdown/Dropdown";
import { FILTER_TYPES } from "../../../constants";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";

const TableFilterComponent = ({
  allColumns,
  stagedFilters,
  setStagedFilters,
  tableData,
}) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [newFiltersCount, setNewFiltersCount] = useState(0);

  const onAddNewFilter = () => {
    setNewFiltersCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (stagedFilters?.length) {
      setNewFiltersCount((prev) => {
        return prev - 1 < 0 ? 0 : prev - 1;
      });
    }
  }, [stagedFilters]);

  useEffect(() => {
    const options = allColumns.reduce((acc, col) => {
      if (
        col?.filterOptions?.filterType &&
        !stagedFilters.some((filter) => filter.id === col.id)
      ) {
        acc.push({
          label: col?.filterOptions?.label || col.Header,
          value: col.id,
          equals: col?.filterOptions?.equals || true,
        });

        if (col?.filterOptions?.filterType === FILTER_TYPES.date) {
          col?.filterOptions?.duplicates?.map((item) => {
            const option = {
              label: item.label,
              value: col.id,
              overwriteLabel: item.label,
              hideCalendar: true,
              to: moment().toISOString(),
            };

            switch (item.id) {
              case "lastSevenDays": {
                acc.push({
                  ...option,
                  from: moment().subtract(7, "days").toISOString(),
                });
                break;
              }
              case "lastThirtyDays": {
                acc.push({
                  ...option,
                  from: moment().subtract(30, "days").toISOString(),
                });
                break;
              }
              case "lastThreeMonths": {
                acc.push({
                  ...option,
                  from: moment().subtract(3, "months").toISOString(),
                });
                break;
              }
              case "lastTwelveMonths": {
                acc.push({
                  ...option,
                  from: moment().subtract(12, "months").toISOString(),
                });
                break;
              }
              default: {
                acc.push({
                  ...option,
                  from: moment().toISOString(),
                });
                break;
              }
            }
            return item;
          });
        }
      }
      return acc;
    }, []);

    setDropdownOptions(options);
  }, [allColumns, stagedFilters]);

  const filters = stagedFilters?.map((filter) => {
    const currentCol = allColumns.find((col) => col.id === filter.id);

    return (
      <currentCol.Filter
        key={filter.id}
        currentCol={currentCol}
        stagedFilters={stagedFilters}
        setStagedFilters={setStagedFilters}
        allColumns={allColumns}
        data={tableData}
        hasValue={!!filter?.value?.values?.length || filter?.value?.value}
        nestedColumnId={currentCol?.filterOptions?.nestedColumnId}
        getNestedColumnOptions={
          currentCol?.filterOptions?.getNestedColumnOptions
        }
      />
    );
  });

  return (
    <div className="flex flex-col pb-2">
      <div className="flex ">
        <div className="font-semibold text-black text-sm pb-2">
          <p>Filter by</p>
        </div>
      </div>
      <div className="flex flex-col">
        {filters}
        <div className="flex w-full">
          <div className="w-1/2 flex flex-col">
            {Array(newFiltersCount)
              .fill(1)
              .map((_item, index) => (
                <Dropdown
                  key={uuidv4()}
                  placeholder="Choose"
                  options={dropdownOptions}
                  menuPlacement="auto"
                  maxMenuHeight={150}
                  disableClear
                  className={`${(!filters?.length || index > 0) && "pt-2"} `}
                  onChange={({
                    value,
                    equals,
                    from,
                    to,
                    hideCalendar,
                    overwriteLabel,
                  }) =>
                    setStagedFilters((prev) => {
                      const tmpObject =
                        allColumns.find((col) => col.id === value) ?? {};
                      const {
                        type,
                        customOptions,
                        filterType,
                        nestedColumnId,
                      } = tmpObject.filterOptions ?? {};

                      const isNestedColumnAlreadySelected = prev.find(
                        (fil) => fil.id === nestedColumnId
                      );
                      return [
                        ...prev.filter((fil) => fil.id !== value),
                        {
                          id: value,
                          value: {
                            value: filterType === "date" || equals,
                            isTrue: undefined,
                            type: type ?? "is",
                            from: from || moment().toISOString(),
                            to: to || moment().toISOString(),
                            hideCalendar,
                            overwriteLabel,
                            customOptions,
                            nestedColumnId,
                          },
                        },
                        // add nested column filter, if any
                        ...(nestedColumnId && !isNestedColumnAlreadySelected
                          ? [
                              {
                                id: nestedColumnId,
                                value: {
                                  value: filterType === "date" || equals,
                                  isTrue: undefined,
                                  type: type ?? "is",
                                  from: from || moment().toISOString(),
                                  to: to || moment().toISOString(),
                                  hideCalendar,
                                  overwriteLabel,
                                  customOptions,
                                },
                              },
                            ]
                          : []),
                      ];
                    })
                  }
                />
              ))}
            {newFiltersCount < dropdownOptions.length && (
              <PlusCircleButton
                title="Add Filter"
                onClick={onAddNewFilter}
                className="flex w-full items-center h-16"
                style={{ color: "#027D61", fontSize: 16 }}
                noHover
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const stagedFiltersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({
      isTrue: PropTypes.bool,
      value: PropTypes.string,
      type: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  })
);

TableFilterComponent.propTypes = {
  allColumns: PropTypes.arrayOf(PropTypes.shape({})),
  stagedFilters: stagedFiltersPropType,
  setStagedFilters: PropTypes.func,
  tableData: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
    )
  ),
};

TableFilterComponent.defaultProps = {
  allColumns: [],
  stagedFilters: [],
  setStagedFilters: undefined,
  tableData: [],
};

export default TableFilterComponent;
