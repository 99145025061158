import React, { useCallback, useEffect, useState } from "react";
import cntl from "cntl";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { pullAllBy as _pullAllBy } from "lodash";
import PropTypes from "prop-types";
import CrossButton from "../CrossButton/CrossButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import TableFilterComponent from "./TableFilterComponent";
import TableGroupComponent from "./TableGroupComponent";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import "./FilterPopOver.css";
import IconButton from "../Buttons/IconButton";
import deleteGreenIcon from "../../assets/images/deleteGreenIcon.svg";
import Modal from "../Modal/Modal";
import greyCrossIcon from "../../assets/images/crossDarkGrey.svg";

const popOverCN = (className) => cntl`
  bg-es-white
  px-6
  pt-4
  pb-6
  shadow-light-lift
  border-es-light-grey
  border
  rounded-lg
  flex
  flex-col
  z-20
  h-fit
  ${className}
`;

const customStyle = {
  valueContainer: {
    maxHeight: "80px",
    overflow: "scroll",
  },
};

const FilterPopOver = ({
  className,
  label,
  onRequestClose,
  enableGrouping,
  setGroupBy,
  groupOptions,
  enableFiltering,
  setAllFilters,
  allColumns,
  isTemplateView,
  templateSettings,
  updateUserTemplateSettings,
  selectedTemplateSetting,
  hideColumns,
  filters,
  tableData,
  applyTemplate,
  deleteTemplate,
  groups,
  groupDispatch,
  clearAllFiltersAndGroups,
  forwardedRef,
  hidePreview,
  filesView,
}) => {
  const [stagedFilters, setStagedFilters] = useState(filters);
  const [stagedGroups, setStagedGroups] = useState(groups);
  const [templateName, setTemplateName] = useState("");
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    setSelectedColumns(
      selectedTemplateSetting?.columns ||
        allColumns
          .filter(
            (c) =>
              c.id !== "selection" &&
              c.Header !== "#" &&
              c.Header !== "Flag" &&
              c.columnId !== "_lineNumber"
          )
          .map((c) => ({
            label: c.editColumnModalHeader || c.Header,
            value: c.id,
          }))
    );
  }, [selectedTemplateSetting, allColumns]);

  useEffect(() => {
    if (selectedTemplateSetting) {
      setStagedGroups(selectedTemplateSetting?.groups || []);
      setStagedFilters(selectedTemplateSetting?.filters || []);
      setTemplateName(selectedTemplateSetting?.name);
    }
  }, [groupDispatch, selectedTemplateSetting]);

  const saveTemplate = useCallback(async () => {
    const userTemplateSettings = templateSettings.filter(
      (temp) => !temp.isAdmin
    );
    const found = userTemplateSettings.find(
      (view) => view.id === selectedTemplateSetting?.id
    );
    let updatedViews = [];
    let newTemplate;
    if (found) {
      updatedViews = userTemplateSettings.map((view) => {
        if (view.id === selectedTemplateSetting.id) {
          newTemplate = {
            ...view,
            name: templateName,
            filters: stagedFilters,
            groups,
            columns: selectedColumns,
          };
          return newTemplate;
        }
        return view;
      });
    } else {
      newTemplate = {
        id: uuidv4(),
        name: templateName,
        filters: stagedFilters,
        groups,
        columns: selectedColumns,
      };
      updatedViews = [...userTemplateSettings, newTemplate];
    }

    updateUserTemplateSettings(updatedViews);
    applyTemplate(newTemplate);
  }, [
    groups,
    selectedColumns,
    selectedTemplateSetting,
    applyTemplate,
    stagedFilters,
    templateName,
    templateSettings,
    updateUserTemplateSettings,
  ]);

  const applyGroup = useCallback(() => {
    setGroupBy(stagedGroups.map((g) => g.value));
  }, [stagedGroups, setGroupBy]);

  const setGroups = useCallback(
    (newGroups) => {
      groupDispatch({
        type: "updateAll",
        groups: newGroups,
      });
    },
    [groupDispatch]
  );

  const onSaveAndApply = useCallback(async () => {
    onRequestClose();
    await saveTemplate();
  }, [onRequestClose, saveTemplate]);

  const preview = useCallback(() => {
    setAllFilters(stagedFilters);
    setGroups(stagedGroups);
    applyGroup();
    const selectedExceptGrouped = _pullAllBy(selectedColumns, groups, "value");
    hideColumns?.(selectedExceptGrouped);
  }, [
    groups,
    applyGroup,
    setGroups,
    stagedGroups,
    hideColumns,
    selectedColumns,
    setAllFilters,
    stagedFilters,
  ]);

  const clear = useCallback(() => {
    setStagedFilters([]);
    setStagedGroups([]);
    setSelectedColumns([]);
    setTemplateName("");
    clearAllFiltersAndGroups();
  }, [clearAllFiltersAndGroups]);

  const close = useCallback(() => {
    onRequestClose();
    if (!selectedTemplateSetting) {
      clear();
    }
  }, [clear, onRequestClose, selectedTemplateSetting]);

  const save = useCallback(() => {
    onRequestClose();
    setAllFilters(stagedFilters);
    setGroups(stagedGroups);
    applyGroup();
    const selectedExceptGrouped = _pullAllBy(
      allColumns
        .filter((c) => c.Header !== "#" && c.Header !== "Flag")
        .map((c) => ({
          label: c.Header,
          value: c.id,
        })),
      groups,
      "value"
    );
    hideColumns?.(selectedExceptGrouped);
  }, [
    allColumns,
    applyGroup,
    stagedGroups,
    setGroups,
    groups,
    hideColumns,
    onRequestClose,
    setAllFilters,
    stagedFilters,
  ]);

  const onConfirmDelete = () => {
    deleteTemplate(selectedTemplateSetting);
    close();
  };

  const disabled = stagedGroups.length === 0 && stagedFilters.length === 0;

  return (
    <>
      <div
        className={popOverCN(className)}
        ref={forwardedRef}
        style={{
          maxHeight: "650px",
          width: "640px",
        }}
      >
        <div className="flex justify-end py-2 space-x-96">
          <div className="flex">
            <p className="font-semibold pr-20">{label}</p>
          </div>
          <div className="flex space-x-96">
            <CrossButton
              className="color"
              onClick={close}
              icon={greyCrossIcon}
              style={{ height: "0.75rem", width: "0.75rem" }}
            />
          </div>
        </div>
        <div
          className="flex flex-col pb-24"
          style={{
            maxHeight: "600px",
            overflowY: "scroll",
          }}
        >
          {enableFiltering && (
            <TableFilterComponent
              allColumns={allColumns}
              stagedFilters={stagedFilters}
              setStagedFilters={setStagedFilters}
              tableData={tableData}
              filesView={filesView}
            />
          )}
          {enableGrouping && (
            <TableGroupComponent
              stagedGroups={stagedGroups}
              setStagedGroups={setStagedGroups}
              groupOptions={groupOptions}
            />
          )}
          {isTemplateView && (
            <>
              <div className="flex pt-2">
                <div className="font-semibold pl-10 pb-3">
                  <p> Include Columns</p>
                </div>
              </div>
              <div className="flex w-1/2 pb-3 pl-10 max-w-sm">
                <Dropdown
                  isMulti
                  options={allColumns
                    .filter(
                      (c) =>
                        c.id !== "selection" &&
                        c.Header !== "#" &&
                        c.Header !== "Flag"
                    )
                    .map((c) => ({
                      label: c.editColumnModalHeader || c.Header,
                      value: c.id,
                    }))}
                  onChange={(values) => {
                    setSelectedColumns(values);
                  }}
                  value={selectedColumns}
                  customStyle={customStyle}
                  maxMenuHeight={5}
                  menuPlacement="auto"
                />
              </div>
            </>
          )}
        </div>

        {isTemplateView && (
          <div className="flex w-1/2 py-3 pl-10 pb-3">
            <Input
              placeholder="Name"
              label="Save This View"
              value={templateName}
              onChange={setTemplateName}
              validation={yup.string().required()}
            />
          </div>
        )}
        <div className="flex justify-between pt-6">
          {isTemplateView && selectedTemplateSetting?.id && (
            <div className="flex pl-10 ">
              <IconButton
                title="Delete"
                icon={deleteGreenIcon}
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
                className="mr-2 pt-2 text-brandGreen"
              />
            </div>
          )}

          <div className="flex justify-start mt-2 w-full">
            {(isTemplateView && (
              <>
                {!hidePreview && (
                  <SecondaryButton
                    title="Preview"
                    onClick={preview}
                    className="mr-2"
                  />
                )}
                <PrimaryButton
                  title="Save & Apply"
                  onClick={onSaveAndApply}
                  disabled={templateName?.trim() === ""}
                />
              </>
            )) || (
              <>
                {!hidePreview && (
                  <PrimaryButton
                    addButton
                    buttonTitle="Apply"
                    onClick={preview}
                    className="mr-2"
                    disabled={disabled}
                  />
                )}
                <PrimaryButton saveButton onClick={save} disabled={disabled} />
                <button
                  type="button"
                  style={{ color: !disabled ? "#027D61" : "#A6A6A6" }}
                  onClick={clear}
                  className="flex flex-row ml-6 mt-2"
                >
                  <p className="text-md tracking-wider font-semibold">
                    Reset Filters
                  </p>
                </button>
              </>
            )}
          </div>
        </div>
        <>
          <Modal
            isOpen={isDeleteModalOpen}
            title="Delete?"
            hideFooter
            onRequestModalClose={() => setIsDeleteModalOpen(false)}
            primaryButtonTitle="Remove"
            primaryButtonOnClick={onConfirmDelete}
            tertiaryButtonTitle="Cancel"
          >
            <div className="flex flex-col">
              <p>Are you sure you want to delete this template?</p>
            </div>
          </Modal>
        </>
      </div>
    </>
  );
};

const filtersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({
      isTrue: PropTypes.bool,
      value: PropTypes.string,
      type: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  })
);

FilterPopOver.propTypes = {
  className: PropTypes.string,
  onRequestClose: PropTypes.func,
  groupOptions: PropTypes.shape({
    hierarchicalOptions: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      )
    ),
    nonHierarchicalOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  label: PropTypes.string,
  enableGrouping: PropTypes.bool,
  setGroupBy: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  enableFiltering: PropTypes.bool,
  setAllFilters: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  allColumns: PropTypes.arrayOf(PropTypes.shape({})),
  isTemplateView: PropTypes.bool,
  updateUserTemplateSettings: PropTypes.func,
  templateSettings: PropTypes.arrayOf(PropTypes.shape({})),
  selectedTemplateSetting: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    filters: filtersPropType,
    groups: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  tableData: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
    )
  ),
  filters: filtersPropType,
  hideColumns: PropTypes.func,
  applyTemplate: PropTypes.func,
  deleteTemplate: PropTypes.func,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  groupDispatch: PropTypes.func,
  clearAllFiltersAndGroups: PropTypes.func,
  forwardedRef: PropTypes.func,
  hidePreview: PropTypes.bool,
  filesView: PropTypes.bool,
};

FilterPopOver.defaultProps = {
  className: undefined,
  onRequestClose: undefined,
  label: undefined,
  enableGrouping: false,
  groupOptions: undefined,
  setGroupBy: () => {},
  enableFiltering: false,
  setAllFilters: undefined,
  allColumns: [],
  isTemplateView: false,
  updateUserTemplateSettings: undefined,
  templateSettings: [],
  selectedTemplateSetting: undefined,
  tableData: [],
  filters: [],
  hideColumns: undefined,
  applyTemplate: undefined,
  deleteTemplate: undefined,
  groups: [],
  groupDispatch: undefined,
  clearAllFiltersAndGroups: undefined,
  forwardedRef: undefined,
  hidePreview: false,
  filesView: false,
};

export default FilterPopOver;
