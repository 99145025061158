import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import getGroupNameFromId from "../../../helpers/Table/getGroupNameFromId";

const headerBgCN = (step) => {
  const sharedCN = cntl`
    tracking-es-wide
    text-es-normal
    leading-6
  `;

  switch (step) {
    case 0:
      return cntl`
        font-es-bold
        bg-es-light-grey
        text-es-dark-grey
        ${sharedCN}
      `;
    case 1:
      return cntl`
        font-es-semibold
        bg-es-semi-grey
        text-es-medium-grey
        ${sharedCN}
      `;
    case 2:
      return cntl`
        font-es-semibold
        bg-es-extra-light-grey
        text-es-medium-grey
        ${sharedCN}
      `;
    default:
      return cntl`
        font-es-semibold
        bg-es-semi-grey
        text-es-medium-grey
        ${sharedCN}
      `;
  }
};

const GroupedHeader = ({ row, step, rowIndex }) => {
  return (
    <div
      className={`sticky left-0 flex flex-row items-center ${headerBgCN(
        step
      )} w-full h-10 py-1 px-2 ${
        rowIndex && "mt-2 border-t border-es-light-grey"
      }`}
      id={row.id}
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      <div className="pl-2 w-64 capitalize">
        {getGroupNameFromId(row.groupByID)}
      </div>
      {!row?.hideGroupByValue && (
        <div className="pl-2 truncate capitalize" style={{ width: "500px" }}>
          {row.groupByVal === "undefined" || !row.groupByVal
            ? "Not Specified"
            : row.groupByVal}
        </div>
      )}
    </div>
  );
};

GroupedHeader.propTypes = {
  row: PropTypes.shape({
    groupByID: PropTypes.string,
    groupByVal: PropTypes.string,
    id: PropTypes.string,
    hideGroupByValue: PropTypes.bool,
  }),
  step: PropTypes.number,
  rowIndex: PropTypes.number,
};

GroupedHeader.defaultProps = {
  row: undefined,
  step: undefined,
  rowIndex: undefined,
};

export default GroupedHeader;
