import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { capitalize, cloneDeep } from "lodash";
// hooks
import DatePicker from "../DatePicker/DatePicker";

// components
import Dropdown from "../Dropdown/Dropdown";
import IconButton from "../Buttons/IconButton";

// imagese and icons
import gearIcon from "../../assets/images/settingsIconGray.svg";
import chatIcon from "../../assets/images/chatIcon.png";
import crossIconWhite from "../../assets/images/crossIconWhite.svg";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import {
  REQUEST_EMPTY_STEP,
  WF_STEP_ACTION_STATUS,
  WORKFLOW_ROLES,
} from "../../../constants";
// import SubmittalTemplateModal from "../Submittals/SubmittalsModal/SubmittalTemplateModal";
import useRequestTemplateReducer from "../../../hooks/useRequestTemplateReducer";
import { toastError, toastMessage } from "../Toast/Toast";
import {
  usePostSubmittal,
  useSubmittals,
} from "../../../hooks/useSubmittal.new";
import usePostWorkflow from "../../../hooks/useWorkflowPost";
import useWorkflow from "../../../hooks/useWorkflow";
import WorkflowTemplateModal from "../WorkflowModal/Template/WorkflowTemplateModal";
import SubmittalTemplateModal from "../Submittals/SubmittalsModal/SubmittalTemplateModal";
import ToggleButton from "../Buttons/ToggleButton";
import Modal from "../Modal/Modal";
import InlineInput from "../Input/InlineInput";
import useRemoveTaskFromCache from "../../../helpers/Workflow/removeTasksFromWorkflow";

export default function RequestWorkflowEditMode({
  workflow,
  requestFormDispatch,
  association,
  projectMembers,
  requestForm,
  createSubmittalTemplate,
  onPatch,
  isWorkflow,
  getStepStatus,
  getIsActiveStep,
}) {
  /* hooks */
  const [templateForm, templateFormDispatch] =
    useRequestTemplateReducer(isWorkflow);
  const postSubmital = usePostSubmittal();
  const { data } = useSubmittals();
  const { mutate: createWorkflow } = usePostWorkflow();
  const { data: workflowData } = useWorkflow({ association });
  const { removeTasksFromCache } = useRemoveTaskFromCache();

  /* useCallback hooks */

  /* state values */
  const [templateOptions, setTemplateOptions] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [requestTemplate, setRequestTemplate] = useState();
  const [originalTemplate, setOriginalTemplate] = useState();
  const [onUpdatingTemplate, setOnUpdatingTemplate] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState({});
  const [roles, setRoles] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState({
    open: false,
    step: {},
  });

  const getAllowEditing = (status) => {
    return (
      status === WF_STEP_ACTION_STATUS.PENDING ||
      status === WF_STEP_ACTION_STATUS.UPCOMING
    );
  };

  /* updaters */
  const handleCloseDeleteModal = useCallback(() => {
    setShowDeleteDialog((prev) => ({ ...prev, open: false, step: {} }));
  }, []);

  useEffect(() => {
    const initialMembers = {};
    // TODO: USE REDUCE?
    workflow?.steps?.forEach((step) => {
      initialMembers[step?.position] = step?.users
        ?.filter(({ reference }) => reference)
        ?.map((user) => ({
          ...user,
          value: user?.reference,
          label: projectMembers?.find((item) => item.value === user?.reference)
            ?.label,
        }));
    });
    setSelectedUsers(initialMembers);
  }, [projectMembers, workflow?.steps]);

  useEffect(() => {
    // keep track of role & status on each step
    let initialRoles = {};
    workflow?.steps?.forEach((step) => {
      initialRoles = {
        ...initialRoles,
        [step?.position]: {
          status: step?.users?.[0]?.status,
          type: step?.users?.[0]?.type,
        },
      };
    });
    setRoles(initialRoles);
  }, [workflow?.steps]);

  useEffect(() => {
    let options = [];
    if (isWorkflow)
      options = workflowData?.workflows?.reduce((acc, item) => {
        if (item.isTemplate === true && item.association === association) {
          acc.push({
            label: `${item.customName} ${
              item.isDefaultTemplate ? "(default)" : ""
            }`,
            value: item.requestWorkflow,
            template: item,
          });
        }
        return acc;
      }, []);
    else
      options = data?.submittals?.reduce((acc, item) => {
        if (item.isTemplate === true && item.association === association) {
          acc.push({
            label: `${item.customName} ${
              item.isDefaultTemplate ? "(default)" : ""
            }`,
            value: item.requestWorkflow,
            template: item,
          });
        }
        return acc;
      }, []);

    options?.push({
      label: (
        <PlusCircleButton
          className={`pt-2 w-full ${options?.length > 1 ? "border-t" : ""}`}
          titleClassName="ml-0"
          title="Create New"
          style={{ color: "#027D61" }}
          onClick={() => setIsTemplateModalOpen(true)}
          noHover
        />
      ),
      value: "create",
    });
    setTemplateOptions(options);
  }, [association, data?.submittals, isWorkflow, workflowData?.workflows]);

  /* functions */

  const getMinDate = useCallback(
    (index) => {
      if (index === 0) {
        return new Date(requestForm?.startDate?.actual);
      }
      return new Date(
        requestForm?.requestWorkflow[0]?.steps?.[index - 1]?.dueDate?.projected
      );
    },
    [requestForm?.requestWorkflow, requestForm?.startDate?.actual]
  );

  const formatOptionLabel = (prop) => {
    const { label, value, isAdmin, template } = prop;
    return (
      <div className="flex flex-row justify-between w-full">
        <div className=" w-full">{label}</div>
        <div className="">
          <div
            role="button"
            onKeyDown={() => {}}
            tabIndex="0"
            onClick={(e) => {
              e.preventDefault();
              setIsEditing(true);
              const origTemp = cloneDeep(template);
              setOriginalTemplate(origTemp);
              templateFormDispatch({ type: "setTemplate", payload: template });
              setIsTemplateModalOpen(true);
            }}
          >
            {value !== "create" && !isAdmin && <img src={gearIcon} alt="" />}
          </div>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    templateFormDispatch({ type: "reset" });
    setIsTemplateModalOpen((prev) => !prev);
    setIsEditing(false);
  };

  const handleTemplateChange = (val) => {
    if (!val?.value) {
      setCurrentTemplate(val);
    } else if (val?.value !== "create") {
      setCurrentTemplate(val);

      // recalculate initial dueDate for each step
      let previousDueDate = requestForm?.startDate?.actual;
      const newSteps = val?.value[0]?.steps?.map((step) => {
        const projectedDueDate = moment(previousDueDate)
          .add(step?.duration?.projected, "d")
          .toISOString();
        previousDueDate = projectedDueDate;
        const updatedStep = {
          ...step,
          dueDate: { projected: projectedDueDate },
        };
        return updatedStep;
      });

      requestFormDispatch({
        type: "editModeWorkflow",
        payload: [{ ...workflow, steps: newSteps }],
      });
    }
  };

  const handleStepDueDateChange = (date, step, idx) => {
    const allSteps = [...workflow?.steps];
    // update current step
    const newStep = {
      ...step,
      dueDate: { projected: date },
    };
    allSteps[idx] = newStep;

    // update all step with new dudate
    let previousProjectedDueDate = date;
    for (let i = idx + 1; i < allSteps?.length; i += 1) {
      const newProjectedDueDate = moment(previousProjectedDueDate)
        .add(allSteps[i]?.duration?.projected, "d")
        .toISOString();

      const nexStep = {
        ...allSteps[i],
        dueDate: {
          projected: newProjectedDueDate,
        },
      };
      allSteps[i] = nexStep;
      previousProjectedDueDate = newProjectedDueDate;
    }

    requestFormDispatch({
      type: "editModeWorkflow",
      payload: [{ ...workflow, steps: allSteps }],
    });
  };

  const handleStepRoleChange = (role, step, idx) => {
    const allSteps = [...workflow?.steps];
    let users = [];
    if (step?.users?.length === 0) {
      // if step.users = [] just add {type, status}
      users.push({ type: role?.value, status: "waiting" });
    } else {
      // set all users to the new role selected
      users = step.users?.map((user) => ({ ...user, type: role.value }));
    }
    const newStep = {
      ...step,
      users,
    };
    allSteps?.splice(idx, 1, newStep);
    requestFormDispatch({
      type: "editModeWorkflow",
      payload: [{ ...workflow, steps: allSteps }],
    });
  };

  const handleStepMemberChange = (selected, step, idx) => {
    setSelectedUsers((prev) => ({ ...prev, [step?.position]: selected }));

    let users = [];

    if (selected?.length === 0) {
      users.push(
        step?.position === 1
          ? {
              status: roles?.[0]?.status ?? "waiting",
              type: isWorkflow ? "approver" : "submitter",
            }
          : {
              status: roles?.[step?.position]?.status ?? "waiting",
              type: roles?.[step?.position]?.type,
            }
      );
    } else {
      users = selected?.map((user) =>
        step?.position === 1
          ? {
              reference: user?.value,
              status: step?.users?.[0]?.status ?? "waiting",
              type: isWorkflow ? step?.users?.[0]?.type : "submitter",
            }
          : {
              reference: user?.value,
              status: step?.users?.[0]?.status ?? "waiting",
              type: step?.users?.[0]?.type,
            }
      );
    }
    const allSteps = [...workflow?.steps];
    const newStep = {
      ...step,
      users,
    };
    allSteps?.splice(idx, 1, newStep);
    requestFormDispatch({
      type: "editModeWorkflow",
      payload: [{ ...workflow, steps: allSteps }],
    });
  };

  const handleDeleteStep = useCallback(
    (deletedStep) => {
      const updatedSteps = workflow?.steps
        ?.filter((step) => deletedStep?.position !== step?.position)
        ?.map((item, index) => ({
          ...item,
          position: index + 1,
        }));

      requestFormDispatch({
        type: "editModeWorkflow",
        payload: [{ ...workflow, steps: updatedSteps }],
      });
      // if deletedStep had tasks associated, remove them from tasks cache
      if (isWorkflow && deletedStep?.tasks?.length) {
        const tasksIds = deletedStep?.tasks?.map((ref) => ref?.split("/")[1]);

        removeTasksFromCache(tasksIds);
      }
    },
    [isWorkflow, removeTasksFromCache, requestFormDispatch, workflow]
  );
  const handleAddNewStep = useCallback(() => {
    const lastStepDueDate =
      workflow?.steps?.[workflow?.steps?.length - 1]?.dueDate;

    // remove these properties from new step
    delete REQUEST_EMPTY_STEP.startDate;
    delete REQUEST_EMPTY_STEP.endDate;
    const newStep = {
      ...REQUEST_EMPTY_STEP,

      // initialize 1st user to default status & type
      users: [
        {
          status: "waiting",
          type: "reviewer",
        },
      ],
      position: workflow?.steps?.length + 1,
      dueDate: {
        projected: moment(lastStepDueDate?.projected)
          ?.add(REQUEST_EMPTY_STEP?.duration?.projected, "d")
          ?.toISOString(),
      },
    };
    const updatedSteps = [...workflow?.steps, newStep];
    requestFormDispatch({
      type: "editModeWorkflow",
      payload: [{ ...workflow, steps: updatedSteps }],
    });
  }, [requestFormDispatch, workflow]);

  const handleAddNewTemplateStep = () => {
    const templateWorkflow = templateForm?.requestWorkflow[0];
    const newStep = {
      duration: {
        projected: 1,
      },
      users: [],
      position: templateWorkflow?.steps?.length + 1,
    };
    const updatedSteps = [...templateWorkflow?.steps, newStep];
    templateFormDispatch({
      type: "requestWorkflow",
      payload: { ...templateWorkflow, steps: updatedSteps },
    });
  };

  const handleDeleteTemplateStep = (deletedStep) => {
    const templateWorkflow = templateForm?.requestWorkflow[0];
    const updatedSteps = templateWorkflow?.steps
      ?.filter(
        (step) => Number(deletedStep?.position) !== Number(step?.position)
      )
      ?.map((item, index) => ({
        ...item,
        position: index + 1,
        description: `Step ${index + 1}`,
      }));

    templateFormDispatch({
      type: "requestWorkflow",
      payload: { ...templateWorkflow, steps: updatedSteps },
    });
  };

  const handleCreateNewTemplate = async () => {
    try {
      setOnUpdatingTemplate(true);
      let isPatchSuccessful = false;

      if (isEditing) {
        /* edit template */
        const patchedTemplate = await onPatch({
          updatedResource: templateForm,
          originalResource: originalTemplate,
        });

        isPatchSuccessful = !!patchedTemplate;
        if (!isPatchSuccessful) {
          throw new Error("Failed to update template. Please try again.");
        } else {
          toastMessage("Succeessfully updated template.");
          // set the new template and update steps
          const template = {
            label: `${patchedTemplate.customName} ${
              patchedTemplate.isDefaultTemplate ? "(default)" : ""
            }`,
            value: patchedTemplate.requestWorkflow,
            template: patchedTemplate,
          };
          handleTemplateChange(template);
        }
      } else if (isWorkflow) {
        /* create new template */
        createWorkflow(
          {
            ...templateForm,
            association,
          },
          {
            onSuccess: (newTemplate) => {
              const template = {
                label: newTemplate.customName,
                value: newTemplate.requestWorkflow,
                template: newTemplate,
              };
              /* ** on successful creating a new template apply it to current workflow ** */
              handleTemplateChange(template);
            },
          }
        );
      } else {
        postSubmital.mutate(
          {
            ...templateForm,
            association,
          },
          {
            onSuccess: (newTemplate) => {
              const template = {
                label: newTemplate.customName,
                value: newTemplate.requestWorkflow,
                template: newTemplate,
              };
              /* ** on successful creating a new template apply it to current submittal ** */
              handleTemplateChange(template);
            },
          }
        );
      }

      templateFormDispatch({ type: "reset" });
    } catch (error) {
      toastError("Failed to update template. Please try again.");
      console.warn(error.message);
    } finally {
      setIsTemplateModalOpen(false);
      setIsEditing(false);
      setOnUpdatingTemplate(false);
    }
  };

  const onToggle = useCallback(() => {
    requestFormDispatch({
      type: "editModeWorkflow",
      payload: [{ ...workflow, isFreeFlow: !workflow?.isFreeFlow }],
    });
  }, [requestFormDispatch, workflow]);

  const handleChange = useCallback(
    (field, editedStep, value) => {
      const updatedSteps = workflow?.steps?.map((step) => {
        if (step?.position !== editedStep?.position) return step;
        return { ...step, [field]: value };
      });

      requestFormDispatch({
        type: "editModeWorkflow",
        payload: [{ ...workflow, steps: updatedSteps }],
      });
    },
    [requestFormDispatch, workflow]
  );

  return (
    <div className="flex flex-col gap-3">
      {requestForm?.status === "draft" && (
        <div className="flex items-start flex-col" style={{ width: "1204px" }}>
          {isWorkflow && (
            <div className="flex pr-2 items-center  py-5">
              <ToggleButton
                isChecked={workflow?.isFreeFlow}
                onToggle={onToggle}
              />
              <div className="flex flex-col">
                <p className="px-2 text-sm font-medium">Free flow</p>
                <p className="px-2 text-xs text-gray-300">
                  Complete steps in any order
                </p>
              </div>
            </div>
          )}
          <div className="w-1/2 mt-4">
            <Dropdown
              placeholder="Select a Template"
              value={currentTemplate}
              options={templateOptions}
              formatOptionLabel={formatOptionLabel}
              onChange={(val) => handleTemplateChange(val)}
              disableSort
              disableClear
            />
          </div>
        </div>
      )}
      {workflow?.steps?.map((step, index) => {
        const isActiveStep = getIsActiveStep?.(step, index);

        const stepStatus = getStepStatus(step, index);
        const allowEditing = getAllowEditing(stepStatus);

        const stepPosition = index + 1;
        return (
          <div
            key={step.id}
            className="relative w-full px-6 gap-6 border flex rounded-lg"
            style={{ minHeight: "140px" }}
          >
            {/* if submittal cannot delete step 1 */}
            {/* if workflow cannot delete step 1 */}
            {requestForm?.status === "draft" && stepPosition !== 1 && (
              <button
                type="button"
                className="absolute -top-2.5 -right-2.5"
                style={{
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  if (isWorkflow) setShowDeleteDialog({ open: true, step });
                  else handleDeleteStep(step);
                }}
              >
                <img
                  alt="delete tag"
                  className="rounded-xl p-1"
                  style={{ backgroundColor: "#027D61" }}
                  src={crossIconWhite}
                />
              </button>
            )}
            <div
              className="flex flex-col items-start gap-3 flex-shrink-0 self-stretch py-6"
              style={{ width: "280px" }}
            >
              <p className=" text-sm text-gray-500 font-bold">
                Step {stepPosition}{" "}
                {!isWorkflow && (
                  <span>- {getStepStatus?.(step, isActiveStep)}</span>
                )}
              </p>
              {isWorkflow && (
                <div className="flex w-full text-area-padding">
                  <InlineInput
                    className="w-full"
                    value={step?.description}
                    placeholder={`${allowEditing ? "Description" : ""}`}
                    editing={allowEditing}
                    textarea
                    width="w-full"
                    rows={3}
                    disabled={!allowEditing}
                    size="base"
                    color="gray-450"
                    onChangeCallback={(val) =>
                      handleChange("description", step, val)
                    }
                    hidePencil
                  />
                </div>
              )}
            </div>
            <IconButton icon={chatIcon} className="h-6 w-6" />
            {isWorkflow && (
              <>
                <div className="border-r min-h-full" />

                <div
                  className="flex justify-center items-center py-6 gap-6"
                  style={{ flex: "1 0 0" }}
                >
                  <p className="flex items-center justify-center w-36 py-2 px-3 rounded-l-full rounded-r-full bg-white text-xs font-semibold text-darkenedGreen border border-darkenedGreen">
                    {getStepStatus?.(step, isActiveStep)}
                  </p>
                </div>
              </>
            )}
            <div className="border-r min-h-full" />
            <div
              className="flex items-start py-6 gap-6"
              style={{ flex: "1 0 0" }}
            >
              <div
                className="flex flex-col items-start gap-3 self-stretch"
                style={{ flex: "1 0 0" }}
              >
                <p className=" text-sm text-gray-500 font-bold">Due On</p>
                <DatePicker
                  value={moment(step?.dueDate?.projected)}
                  dateFormat="MMMM dd, yyyy"
                  onChange={(val) => {
                    handleStepDueDateChange(val, step, index);
                  }}
                  minDate={getMinDate(index)}
                  disabled={!allowEditing}
                />
              </div>
            </div>
            <div className="border-r min-h-full" />
            <div
              className="flex items-start py-6 gap-6"
              style={{ flex: "1 0 0" }}
            >
              <div
                className="flex flex-col items-start gap-3 self-stretch"
                style={{ flex: "1 0 0" }}
              >
                {!isWorkflow && stepPosition === 1 && (
                  <p className=" text-sm text-gray-500 font-bold">Submitter</p>
                )}
                {isWorkflow && stepPosition === 1 && (
                  <div className="w-full">
                    <Dropdown
                      onChange={(val) => handleStepRoleChange(val, step, index)}
                      placeholder="Select Role"
                      options={WORKFLOW_ROLES}
                      value={{
                        label: capitalize(step?.users?.[0]?.type) || "Reviewer",
                        value: step?.users?.[0]?.type || "reviewer",
                      }}
                      menuPlacement="auto"
                      isDisabled={!allowEditing}
                    />
                  </div>
                )}
                {stepPosition !== 1 && (
                  <div className="w-full">
                    <Dropdown
                      onChange={(val) => handleStepRoleChange(val, step, index)}
                      placeholder="Select Role"
                      options={
                        isWorkflow
                          ? WORKFLOW_ROLES
                          : [
                              { label: "Reviewer", value: "reviewer" },
                              { label: "Approver", value: "approver" },
                            ]
                      }
                      value={{
                        label: capitalize(step?.users?.[0]?.type) || "Reviewer",
                        value: step?.users?.[0]?.type || "reviewer",
                      }}
                      menuPlacement="auto"
                      isDisabled={!allowEditing}
                    />
                  </div>
                )}

                <div className="w-full">
                  <Dropdown
                    onChange={(val) => handleStepMemberChange(val, step, index)}
                    placeholder=""
                    hideDropdownIndicator
                    options={projectMembers}
                    value={selectedUsers?.[step?.position]}
                    menuPlacement="auto"
                    isMulti
                    isDisabled={!allowEditing}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {((workflow?.steps && requestForm?.status === "draft") ||
        workflow?.isFreeFlow) && (
        <PlusCircleButton
          title="Add Step"
          style={{ color: "#027D61" }}
          onClick={() => handleAddNewStep()}
          noHover
        />
      )}

      {isTemplateModalOpen && !isWorkflow && (
        <SubmittalTemplateModal
          title={`${
            isEditing
              ? `Editing ${templateForm?.customName}`
              : "Create Template"
          }`}
          isModalOpen={isTemplateModalOpen}
          handleCloseModal={handleCloseModal}
          memberOptions={projectMembers}
          template={requestTemplate}
          setTemplate={setRequestTemplate}
          canCreateTemplate
          createSubmittalTemplate={createSubmittalTemplate}
          handleAddNewStep={handleAddNewTemplateStep}
          handleDeleteStep={handleDeleteTemplateStep}
          handleCreateNewTemplate={handleCreateNewTemplate}
          templateForm={templateForm}
          dispatch={templateFormDispatch}
          workflow={templateForm?.requestWorkflow?.[0]}
          isEditing={isEditing}
          currentTemplate={currentTemplate?.template}
          isSaving={onUpdatingTemplate}
        />
      )}
      {isTemplateModalOpen && isWorkflow && (
        <WorkflowTemplateModal
          title={`${
            isEditing
              ? `Editing ${templateForm?.customName}`
              : "Create Template"
          }`}
          isModalOpen={isTemplateModalOpen}
          handleCloseModal={handleCloseModal}
          memberOptions={projectMembers}
          template={requestTemplate}
          setTemplate={setRequestTemplate}
          canCreateTemplate
          createSubmittalTemplate={createSubmittalTemplate}
          handleAddNewStep={handleAddNewTemplateStep}
          handleDeleteStep={handleDeleteTemplateStep}
          handleCreateNewTemplate={handleCreateNewTemplate}
          templateForm={templateForm}
          dispatch={templateFormDispatch}
          workflow={templateForm?.requestWorkflow?.[0]}
          isEditing={isEditing}
          currentTemplate={currentTemplate?.template}
          isSaving={onUpdatingTemplate}
        />
      )}

      <Modal
        alert
        title="Delete Request"
        isOpen={showDeleteDialog.open}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={() => {
          handleDeleteStep(showDeleteDialog.step);
          handleCloseDeleteModal();
        }}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={handleCloseDeleteModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <p className="text-base mb-2">
          Deleting this step and saving will delete any related tasks. Are you
          sure you want to delete this step? Once deleted and saved, it cannot
          be recovered.
        </p>
      </Modal>
    </div>
  );
}
