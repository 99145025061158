// APIs

// Framework Tools
import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router";
// Constants
import { WF_STATUS_TYPES } from "../../constants";

// helpers
import { getLocationState } from "../../helpers/Navigation";

// hooks
import useWFSingleViewData from "./useWFSingleViewData";

// components
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
import InlineInput from "../../stories/Components/Input/InlineInput";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import WidgetContainer from "../../stories/Components/Widget/WidgetContainer";
import Pill from "../../stories/Components/Pill/Pill";
import SubmittalStatusPill from "../Submittals/SubmittalStatusPill";
import WorkflowDetails from "../../stories/Components/Workflow/WorkflowDetails";
import WorkflowSteps from "../../stories/Components/Workflow/WorkflowSteps";
import PopoverComments from "../../stories/Components/PopoverComments";
import Modal from "../../stories/Components/Modal/Modal";
import ModalConfirmAll from "../../stories/Components/Modal/ModalConfirmAll";
import RequestCompleteModal from "../../stories/Components/Requests/RequestModals/RequestCompleteModal";
import FilesTable from "../../stories/Components/FilesTable/FilesTable";
import WorkflowHistory from "../../stories/Components/Workflow/WorkflowHistory";

export default function SingleWorkflowView() {
  const { workflowId } = useParams();
  const { state } = useLocation();
  const { isCommentNotification } = getLocationState(state);
  const {
    associationMembers,
    workflowHistory,
    activeTabIndex,
    subMenuOptions,
    editedWorkflow,
    workflowSteps,
    attachmentMap,
    workflowForm,
    association,
    currentUser,
    disableEdit,
    ballInCourt,
    workflowDD,
    commentMap,
    distroList,
    propertyDD,
    initiated,
    projectDD,
    userDict,
    editing,
    isDraft,
    workflow,
    loading,
    associatedFiles,
    showCommentsPopover,
    showConfirm,
    dropdownItems,
    showInitiateModal,
    workflowVoid,
    stepTasksInfo,
    canComplete,
    setLoading,
    addFiles,
    removeFilesAndUpdateApi,
    handleUpdateFile,
    handleFileClone,
    onAddFilesCallback,
    removeWorkflowFiles,
    patchWorkflow,
    handleEditClick,
    handleDistroList,
    setAttachmentMap,
    checkStepComplete,
    handleSendReminder,
    handleArtifactsList,
    handleFinishEditing,
    workflowFormDispatch,
    handleTargetCompletion,
    handleProjectedCompletion,
    handleChangeWorkflowTitle,
    setShowCommentsPopover,
    setShowConfirm,
    handleInitiateWorkflow,
    setShowInitiateModal,
    handleDeleteWorkflow,
    handleVoidWorkflow,
    onCompleteWorkflow,
    getIconsToHide,
    workflowActionReview,
    workflowActionSubmit,
    setStepTasksInfo,
    setDisableEdit,
    setShowStepDetails,
    handleTabClick,
    refetch,
  } = useWFSingleViewData({ workflowId, isCommentNotification });

  const tabs = useMemo(() => {
    const permissionedTabs = {
      tabs: [
        {
          title: "Details",
          content: (
            <WorkflowDetails
              workflow={workflow}
              loading={loading}
              association={association}
              workflowForm={workflowForm}
              workflowFormDispatch={workflowFormDispatch}
              isDraft={isDraft}
              editing={editing}
              projectDD={projectDD}
              propertyDD={propertyDD}
              associationMembers={associationMembers}
              ballInCourt={ballInCourt}
              distroList={distroList}
              initiated={initiated}
              handleDistroList={handleDistroList}
              handleArtifactsList={handleArtifactsList}
              workflowDDOptions={workflowDD}
              handleTargetCompletion={handleTargetCompletion}
              handleProjectedCompletion={handleProjectedCompletion}
              workflowHistory={workflowHistory}
              commentMap={commentMap}
            />
          ),
        },
        {
          title: "Workflow",
          content: (
            <WorkflowSteps
              loading={loading}
              setLoading={setLoading}
              setAttachmentMap={setAttachmentMap}
              attachmentMap={attachmentMap}
              handleSendReminder={handleSendReminder}
              workflowActionSubmit={workflowActionSubmit}
              workflowActionReview={workflowActionReview}
              patchWorkflow={patchWorkflow}
              editedWorkflow={editedWorkflow}
              commentMap={commentMap}
              editing={editing}
              workflowSteps={workflowSteps}
              checkStepComplete={checkStepComplete}
              workflowForm={workflowForm}
              workflowFormDispatch={workflowFormDispatch}
              projectMembers={associationMembers}
              userDict={userDict}
              workflowHistory={workflowHistory}
              workflow={workflowForm?.requestWorkflow?.[0]}
              requestForm={workflowForm}
              requestFormDispatch={workflowFormDispatch}
              association={workflowForm?.association}
              updateAssociatedFiles={addFiles}
              stepTasksInfo={stepTasksInfo}
              setStepTasksInfo={setStepTasksInfo}
              setDisableEditWF={setDisableEdit}
              disableEditWF={disableEdit}
              setShowStepDetails={setShowStepDetails}
              refetchWorkFlow={refetch}
            />
          ),
        },
        {
          title: "History",
          content: (
            <WorkflowHistory
              loading={loading}
              attachmentMap={attachmentMap}
              commentMap={commentMap}
              workflow={workflow}
              userDict={userDict}
            />
          ),
        },
        {
          title: "Files",
          content: (
            <FilesTable
              files={associatedFiles}
              onAddFilesCallback={onAddFilesCallback}
              removeFilesAndUpdateApi={removeFilesAndUpdateApi}
              onRemoveFilesCallback={removeWorkflowFiles}
              resourceName="Workflow"
              association={`Workflow/${workflowId}`}
              hasDeletePermission={currentUser?.hasPermission?.(
                "workflow",
                "can_delete"
              )}
              hasWritePermission={currentUser?.hasPermission?.(
                "workflow",
                "can_create"
              )}
              hasEditPermission
              handleFileClone={handleFileClone}
              handleUpdateFile={handleUpdateFile}
              hideFileActionsIcons={getIconsToHide()}
            />
          ),
        },
      ],
    };

    permissionedTabs.detailsIndex = 0;
    permissionedTabs.workflowIndex = 1;
    permissionedTabs.historyIndex = 2;
    permissionedTabs.workflowFilesIndex = 3;

    return permissionedTabs;
  }, [
    refetch,
    addFiles,
    associatedFiles,
    association,
    associationMembers,
    attachmentMap,
    ballInCourt,
    checkStepComplete,
    commentMap,
    currentUser,
    disableEdit,
    distroList,
    editedWorkflow,
    editing,
    getIconsToHide,
    handleArtifactsList,
    handleDistroList,
    handleFileClone,
    handleProjectedCompletion,
    handleSendReminder,
    handleTargetCompletion,
    handleUpdateFile,
    initiated,
    isDraft,
    loading,
    onAddFilesCallback,
    patchWorkflow,
    projectDD,
    propertyDD,
    removeFilesAndUpdateApi,
    removeWorkflowFiles,
    setAttachmentMap,
    setDisableEdit,
    setLoading,
    setShowStepDetails,
    setStepTasksInfo,
    stepTasksInfo,
    userDict,
    workflow,
    workflowActionReview,
    workflowActionSubmit,
    workflowDD,
    workflowForm,
    workflowFormDispatch,
    workflowHistory,
    workflowId,
    workflowSteps,
  ]);

  return (
    <>
      <SiteHeader
        title={
          <div className="flex flex-col items-start">
            <InlineInput
              width="w-full"
              size="custom4xl"
              value={workflow?.customName ?? workflow?.name ?? "Loading..."}
              editing={editing}
              loading={false}
              disabled={false}
              fontWeight="bold"
              color="gray-650"
              onChangeCallback={(val) => handleChangeWorkflowTitle(val)}
              hidePencil
              isHeaderTitle
            />
            {!!workflow?.status && (
              <div
                id="pill-icon-container"
                className="flex items-center gap-3 mt-1"
              >
                <SubmittalStatusPill
                  submittalStatus={workflow?.status}
                  canComplete={canComplete}
                />
                {workflow?.status !== WF_STATUS_TYPES?.DRAFT &&
                  workflow?.status !== WF_STATUS_TYPES?.VOID && (
                    <Pill
                      value={`Round ${workflow?.round}`}
                      textSize="text-xs"
                      border="border border-gray-450"
                      background="bg-white"
                      textColor="text-gray-450"
                    />
                  )}
              </div>
            )}
          </div>
        }
        buttons={
          workflow?.status !== WF_STATUS_TYPES?.VOID && (
            <PrimaryButton
              disabled={false}
              title="Actions"
              dropdownItems={dropdownItems}
              className="dropdown-btn"
              large
              subMenuItems={subMenuOptions}
            />
          )
        }
      />
      <WidgetContainer
        disableEditing={disableEdit}
        loading={loading}
        className="p-4 border-gray-200 shadow-lg border rounded-md"
        style={{ minWidth: "903px" }}
        isEditing={editing}
        handleEditClick={handleEditClick}
        onFinishEditing={() => handleFinishEditing()}
        onOpenComment={() => setShowCommentsPopover(true)}
        tabs={tabs?.tabs}
        activeIndex={activeTabIndex}
        onTabClick={(index) => {
          handleTabClick(index, tabs);
        }}
        resetResourceState={() => {}}
      />
      <div className="flex flex-col w-full mt-6">
        <RequestCompleteModal
          requestData={workflow}
          isWorkflow
          onComplete={onCompleteWorkflow}
        />
      </div>

      <ModalConfirmAll
        showConfirmModal={showConfirm}
        setShowConfirmModal={setShowConfirm}
        modalAction={
          workflowVoid
            ? `Void workflow ${workflow?.customName}`
            : "Delete Workflow"
        }
        customMessage={
          !workflowVoid &&
          "By deleting this Workflow you're also deleting all of the related Tasks, are you sure? This action cannot be undone."
        }
        noNavigate={workflowVoid ? handleVoidWorkflow : handleDeleteWorkflow}
        primaryButtonTitle={workflowVoid ? "Void" : "Yes"}
      />

      {showInitiateModal && (
        <Modal
          title="Initiate Workflow"
          isOpen={showInitiateModal}
          primaryButtonTitle="Yes"
          primaryButtonOnClick={handleInitiateWorkflow}
          tertiaryButtonTitle="Cancel"
          onRequestModalClose={() => setShowInitiateModal(false)}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          alert
          hideFooter
        >
          <>
            <p className="text-base mb-2">
              Would you like to set the start date to today&apos;s date?
            </p>
          </>
        </Modal>
      )}

      {showCommentsPopover ? (
        <PopoverComments
          isOpen={showCommentsPopover}
          resourceReference={`Workflow/${workflowId}`}
          association={workflow?.association}
          onClose={() => setShowCommentsPopover(false)}
        />
      ) : null}
    </>
  );
}
