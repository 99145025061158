import cntl from "cntl";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import * as yup from "yup";
import { NO_ATTRIBUTE_OPTION_MSG } from "../../../constants";
import sortAndGroupByFieldType from "../../../helpers/sortAndGroupByFieldType";
import Checkbox from "../Checkbox/Checkbox";
import DatePicker from "../DatePicker/DatePicker";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import PureAttributeWidget from "./PureAttributeWidget";
import useAttributes from "../../../hooks/useAttributes";

const labelWidth = {
  minWidth: "12.5rem",
  width: "10.5vw",
  wordBreak: "break-word",
  marginRight: 10,
};

const splitRowCN = cntl`
  flex
  justify-between
  items-center
  mt-1
  ESInputLabel
`;

const AttributeWidget = ({
  resource,
  dispatch,
  loading,
  defaultAttributes = [],
  disableEditing,
  setNewAttributes,
  newAttributes,
  editing,
}) => {
  const [, reloadAttributes, selectedMeasurements] = useAttributes();

  const onChangeFieldType = useCallback(
    (id, value, unitId, optionId) => {
      dispatch({
        type: "measurement",
        id,
        value,
        unitId,
        optionId,
      });
    },
    [dispatch]
  );

  const determineTextFieldNeeded = (input) => {
    if (typeof input !== "string" || input === "") return false;
    if (input.length > 20) return true;

    return false;
  };

  const filteredItems = useMemo(() => {
    if (selectedMeasurements?.length || newAttributes?.length) {
      const allMeasurements = [...selectedMeasurements];
      const { additionalInfo: info } = resource;
      const temp = allMeasurements?.reduce(
        (list, item) => {
          const found = (info ?? []).find((x) => item.id === x?.id);
          if (found) {
            const foundValue =
              found.value ||
              item.options.find(({ id }) => id === found.optionId).name;
            const foundUnit = item.unit.options.find(
              ({ id }) => id === found.unitId
            )?.name;

            const value =
              item?.fieldType === "date-picker"
                ? moment(found.value).format("MM/DD/YYYY")
                : `${foundValue}${foundUnit ? ` ${foundUnit}` : ""}`;

            list.items.push({
              title: item.detail,
              id: item.id,
              value: (
                <p
                  className={splitRowCN}
                  title={value}
                  aria-label={`${item.id}`}
                >
                  {`${value}`}
                </p>
              ),
              field: item?.fieldType,
            });
            list.measurementFields.push(item);
          }
          return list;
        },
        { items: [], measurementFields: [] }
      );
      return temp;
    }

    return { items: [], measurementFields: [] };
  }, [newAttributes, resource, selectedMeasurements]);

  const getMeasurementInput = useCallback(
    (
      detail,
      fieldType,
      options,
      unit,
      defaultValue,
      defaultOnChange,
      defaultTitle,
      id
    ) => {
      const unitOptions = [];
      if (unit?.options?.length) {
        unit.options.forEach(({ id: unitOptionId, name }) =>
          unitOptions.push({ label: name, value: unitOptionId })
        );
      }

      const { value, unitId, optionId } =
        resource?.additionalInfo?.find((m) => m.id === id) ?? {};

      let returnValue;

      switch (fieldType) {
        case "number-entry":
          returnValue = (
            <div key={id} className="flex flex-row w-full items-center gap-2">
              <p className="ESInputLabel text-left" style={labelWidth}>
                {defaultTitle ?? detail}
              </p>
              <div className="flex flex-row justify-evenly w-full">
                <Input
                  isTextarea={determineTextFieldNeeded(defaultValue ?? value)}
                  inlineLabel
                  mainWrapperClassName={unitOptions?.length ? "w-44" : "w-full"}
                  placeholder={defaultTitle ?? detail}
                  onChange={(val) =>
                    defaultOnChange
                      ? defaultOnChange(val)
                      : onChangeFieldType(id, val, unitId)
                  }
                  value={defaultValue ?? value}
                  validation={yup
                    .number()
                    .transform((v, o) => (o === "" ? undefined : v))}
                  disableClear
                />
                {!!unitOptions?.length && (
                  <div className="flex flex-row items-center gap-2 w-full pl-2">
                    <p className="ESInputLabel justify-end">Unit</p>
                    <Dropdown
                      noOptionsMessage={NO_ATTRIBUTE_OPTION_MSG}
                      className="w-full text-left"
                      placeholder="Select"
                      options={unitOptions}
                      onChange={({ value: changeValue }) =>
                        onChangeFieldType(id, value, changeValue)
                      }
                      value={unitOptions?.find((opt) => opt.value === unitId)}
                      disableClear
                    />
                  </div>
                )}
              </div>
            </div>
          );
          break;
        case "date-picker":
          returnValue = (
            <div key={id} className="flex flex-row w-full items-center gap-2">
              <p className="ESInputLabel text-left" style={labelWidth}>
                {defaultTitle ?? detail}
              </p>
              <DatePicker
                labelClassName="ESInputLabel text-left"
                className="w-full items-center"
                value={defaultValue ?? value}
                onChange={(val) =>
                  defaultOnChange
                    ? defaultOnChange(val)
                    : onChangeFieldType(id, val)
                }
                validation={yup.date()}
              />
            </div>
          );
          break;
        case "ddl":
          {
            const optionsDDL = options?.map(({ id: optionDDLId, name }) => ({
              label: name,
              value: optionDDLId,
            }));

            returnValue = (
              <div key={id} className="flex flex-row gap-2 items-center w-full">
                <p className="ESInputLabel text-left" style={labelWidth}>
                  {defaultTitle ?? detail}
                </p>
                <div className="flex w-full">
                  <Dropdown
                    noOptionsMessage={NO_ATTRIBUTE_OPTION_MSG}
                    className="flex-1 text-left"
                    placeholder="Select"
                    options={optionsDDL}
                    onChange={({ value: changeValue }) =>
                      defaultOnChange
                        ? defaultOnChange(changeValue)
                        : onChangeFieldType(id, null, null, changeValue)
                    }
                    value={optionsDDL?.find(
                      (opt) =>
                        opt.value === defaultValue || opt.value === optionId
                    )}
                    disableClear
                  />
                </div>
              </div>
            );
          }
          break;
        case "check-box":
          returnValue = (
            <div key={id} className="flex flex-row">
              <p className="ESInputLabel text-left" style={labelWidth}>
                {defaultTitle ?? detail}
              </p>
              <Checkbox
                placeholder={detail}
                size={6}
                className="flex items-center"
                value={defaultValue ?? value}
                checked={value === "Yes"}
                onChange={(isChecked) =>
                  defaultOnChange
                    ? defaultOnChange(isChecked ? "Yes" : "No")
                    : onChangeFieldType(id, isChecked ? "Yes" : "No")
                }
              />
            </div>
          );
          break;
        case "text-entry":
        default:
          returnValue = (
            <div key={id} className="flex flex-row items-center w-full">
              <p className="ESInputLabel text-left" style={labelWidth}>
                {defaultTitle ?? detail}
              </p>
              <Input
                isTextarea={determineTextFieldNeeded(defaultValue ?? value)}
                mainWrapperClassName="flex w-full"
                placeholder={defaultTitle ?? detail}
                onChange={(val) =>
                  defaultOnChange
                    ? defaultOnChange(val)
                    : onChangeFieldType(id, val)
                }
                value={defaultValue ?? value}
                validation={yup.string()}
                disableClear
              />
              {!!unitOptions?.length && (
                <Dropdown
                  noOptionsMessage={NO_ATTRIBUTE_OPTION_MSG}
                  className="pl-2 text-left border flex flex-row"
                  label="Unit"
                  placeholder="Select"
                  options={unitOptions}
                  onChange={({ value: changeValue }) =>
                    onChangeFieldType(id, value, changeValue)
                  }
                  value={unitOptions?.find((opt) => opt.value === unitId)}
                  disableClear
                />
              )}
            </div>
          );
      }

      return (
        <div key={id} className="flex flex-row w-full text-center">
          <div className="flex flex-row justify-between w-full">
            {returnValue}
          </div>
        </div>
      );
    },
    [resource?.additionalInfo, onChangeFieldType]
  );

  const handleAddElements = (elements, attributes) => {
    elements.forEach(({ id, fieldType, data: { value, unitId, optionId } }) => {
      let finalValue = value;
      if (!finalValue) {
        finalValue = fieldType === "check-box" ? "No" : "";
      }
      dispatch({
        type: "measurement",
        value: finalValue,
        unitId,
        optionId,
        id,
      });
    });

    setNewAttributes(attributes);
  };

  const handleDeleteElement = (id) => {
    dispatch({
      type: "measurement",
      operation: "remove",
      id,
    });
  };

  const attributes = useMemo(() => {
    const combinedAttributes = [
      ...defaultAttributes,
      ...filteredItems?.measurementFields,
    ];
    const sortedAttributes = sortAndGroupByFieldType(combinedAttributes);

    return sortedAttributes
      ?.map(
        ({ detail, fieldType, options, unit, value, onChange, title, id }) => {
          return {
            input: getMeasurementInput(
              detail,
              fieldType,
              options,
              unit,
              value,
              onChange,
              title,
              id
            ),
            display: title
              ? { title, value, field: fieldType }
              : filteredItems?.items?.find((item) => item.id === id),
            defaultAttribute: title,
            id,
          };
        }
      )
      .filter((el) => !!el);
  }, [
    defaultAttributes,
    filteredItems?.items,
    filteredItems?.measurementFields,
    getMeasurementInput,
  ]);

  return (
    <PureAttributeWidget
      resource={resource}
      values={attributes}
      loading={loading}
      onAdd={handleAddElements}
      editing={editing}
      onDelete={handleDeleteElement}
      disableEditing={disableEditing}
      measurements={selectedMeasurements}
      reloadAttributes={reloadAttributes}
    />
  );
};

AttributeWidget.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  defaultAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  disableEditing: PropTypes.bool,
  setNewAttributes: PropTypes.func,
  newAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  editing: PropTypes.bool,
};

AttributeWidget.defaultProps = {
  resource: undefined,
  dispatch: undefined,
  loading: false,
  defaultAttributes: [],
  disableEditing: false,
  setNewAttributes: undefined,
  newAttributes: [],
  editing: false,
};

export default AttributeWidget;
