import moment from "moment";

const getSiteHeaderFilterDisplay = (id, filterValue) => {
  if (
    filterValue?.valueLabels &&
    Object.values(filterValue?.valueLabels)?.length
  ) {
    return `${id}: ${Object.values(filterValue?.valueLabels).join(",")}`;
  }
  if (filterValue?.isTrue !== undefined) {
    return `${id}: ${filterValue?.isTrue}`;
  }

  const isSameDay =
    moment(filterValue?.from).format("DD MMMM, YYYY") ===
    moment(filterValue?.to).format("DD MMMM, YYYY");

  if (isSameDay) {
    return `${id}: ${moment(filterValue?.from).format("DD MMMM, YYYY")}`;
  }
  return `${id}: ${moment(filterValue?.from).format(
    "DD MMMM, YYYY"
  )} - ${moment(filterValue?.to).format("DD MMMM, YYYY")}`;
};

export default getSiteHeaderFilterDisplay;
