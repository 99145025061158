import { useReducer } from "react";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

const defaultState = [];

const reducer = (attributes, action) => {
  switch (action.type) {
    case "reset":
      return cloneDeep(action.attributes) ?? defaultState;
    case "changeAttributeEditing": {
      return attributes.map((p, idx) => {
        if (idx === action.index) {
          return {
            ...p,
            isEditing: !p.isEditing,
          };
        }
        return p;
      });
    }
    case "removeAttribute": {
      return attributes.filter((_attribute, idx) => idx !== action.index);
    }
    case "selectAttribute": {
      return attributes.map((attribute, idx) => {
        if (idx === action.index) {
          return {
            ...attribute,
            selected: !attribute.selected,
            wasUpdated: true,
          };
        }
        return attribute;
      });
    }
    case "changeAttributeDetail": {
      return attributes.map((p, idx) => {
        if (idx === action.index) {
          return {
            ...p,
            detail: action.value,
            wasUpdated: true,
          };
        }
        return p;
      });
    }
    case "changeFieldType": {
      return attributes.map((attribute, idx) => {
        if (idx === action.index) {
          if (action.value?.hasUnits) {
            return {
              ...attribute,
              fieldType: action.value?.value,
              unit: {
                fieldType: "ddl",
                options: [],
              },
              options: [],
              wasUpdated: true,
            };
          }
          return {
            ...attribute,
            fieldType: action.value?.value,
            unit: undefined,
            options: [],
            wasUpdated: true,
          };
        }
        return attribute;
      });
    }
    case "changeOptions": {
      return attributes.map((attribute, idx) => {
        if (idx === action.index) {
          return {
            ...attribute,
            options: action.value?.map((opt) => ({
              label: opt?.label,
              value: uuidv4(),
            })),
            wasUpdated: true,
          };
        }
        return attribute;
      });
    }
    case "changeUnitOptions": {
      return attributes.map((attribute, idx) => {
        if (idx === action.index) {
          return {
            ...attribute,
            unit: {
              ...attribute?.unit,
              options: action.value?.map((opt) => ({
                label: opt?.label,
                value: uuidv4(),
              })),
            },
            wasUpdated: true,
          };
        }
        return attribute;
      });
    }
    case "addAttribute": {
      return [
        ...attributes,
        {
          detail: "",
          id: uuidv4(),
          selected: true,
          group: "Custom",
          fieldType: "text-entry",
          isEditing: true,
          wasAdded: true,
        },
      ];
    }
    default:
      return attributes;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};
