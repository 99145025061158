import React, { useCallback } from "react";
import PropTypes from "prop-types";
import ResourceCard from "../ResourceCard/ResourceCard";
import SettingsCard from "../SettingsCard/Index";
import GroupedHeader from "../Table/GroupedHeader";

const PureCardGrid = ({
  resourceName,
  rows,
  onClick,
  isGrouped: grouped,
  hideGroupedHeader,
  detailLabel,
  customFilter,
}) => {
  const card = useCallback(
    (row) => {
      switch (resourceName) {
        case "SettingsAndConfiguration":
          return (
            <SettingsCard
              item={{
                id: row?.values?.id,
                title: row?.values?.title,
                type: row?.values?.type,
                description: row?.values?.description,
              }}
              onClick={onClick}
            />
          );
        default:
          return (
            <ResourceCard
              id={row?.values?.id}
              title={row?.values?.name || row?.values?.title}
              imageReference={row?.values?.image || row?.original?.primaryImage}
              resourceName={resourceName}
              detail={{
                label: row?.values?.category || detailLabel || "Type",
                value:
                  row?.values?.subcategory ||
                  row?.values?.propertyType ||
                  row?.values?.projectType ||
                  row?.values?.level,
              }}
              onClick={() => {
                onClick(row?.id);
              }}
            />
          );
      }
    },
    [detailLabel, onClick, resourceName]
  );

  const getTableRow = useCallback(
    (row) => {
      return card(row);
    },
    [card]
  );

  const filteredRows = useCallback(
    (rs) => {
      if (customFilter) {
        const newRs = rs.filter(customFilter);
        return newRs;
      }
      return rs;
    },
    [customFilter]
  );

  const getTableGroups = useCallback(
    (tableRows, step = 0) => {
      return tableRows.map((row, index) => {
        if (row.isGrouped) {
          return (
            <React.Fragment key={row.id}>
              {!hideGroupedHeader && (
                <GroupedHeader row={row} step={step} rowIndex={index} />
              )}

              <div
                className={`flex flex-wrap w-full ${
                  !row?.subRows[0]?.isGrouped && "gap-x-6 gap-y-9 mt-9 mb-7"
                }`}
              >
                {getTableGroups(filteredRows(row.subRows), step + 1)}
              </div>
            </React.Fragment>
          );
        }
        return getTableRow(row);
      });
    },
    [getTableRow, hideGroupedHeader, filteredRows]
  );

  return (
    <>
      {!grouped ? (
        <div className="flex flex-wrap mt-2 gap-x-6 gap-y-9">
          {filteredRows(rows)?.map((row) => {
            return card(row);
          })}
        </div>
      ) : (
        <>{getTableGroups(rows)}</>
      )}
    </>
  );
};

PureCardGrid.propTypes = {
  resourceName: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      category: PropTypes.string,
      subcategory: PropTypes.string,
      type: PropTypes.string,
      link: PropTypes.string,
      valuation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isFavorited: PropTypes.bool,
    })
  ),
  onClick: PropTypes.func,
  isGrouped: PropTypes.bool,
  hideGroupedHeader: PropTypes.bool,
  detailLabel: PropTypes.string,
  customFilter: PropTypes.func,
};

PureCardGrid.defaultProps = {
  resourceName: undefined,
  rows: undefined,
  onClick: undefined,
  isGrouped: false,
  hideGroupedHeader: false,
  detailLabel: undefined,
  customFilter: undefined,
};

export default PureCardGrid;
