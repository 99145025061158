import { useEffect, useState } from "react";

const usePDFViewerData = () => {
  const width = 750;
  const height = width * 1.5;

  const [pdf, setPdf] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageViewports, setPageViewports] = useState(null);

  useEffect(() => {
    setPageViewports(null);

    if (!pdf) {
      return;
    }

    // loads all the pages from a PDF document and calculate their viewports concurrently
    (async () => {
      const pageNumbers = Array.from(
        { length: pdf.numPages },
        (_, index) => index + 1
      );

      const nextPageViewports = await Promise.all(
        pageNumbers.map(async (pageNumber) => {
          const page = await pdf.getPage(pageNumber);
          return page.getViewport({ scale: 1 });
        })
      );

      setPageViewports(nextPageViewports);
    })();
  }, [pdf]);

  const getPageHeight = (pageIndex) => {
    if (!pageViewports) {
      throw new Error("getPageHeight() called too early");
    }

    const pageViewport = pageViewports[pageIndex];
    const scale = width / pageViewport.width;
    const actualHeight = pageViewport.height * scale;

    return actualHeight;
  };

  const onLoadSuccess = (payload) => {
    setPdf(payload);
    setIsLoading(false);
  };

  const onLoadError = () => {
    setIsLoading(false);
  };

  return {
    isLoading,
    pdf,
    pageViewports,
    height,
    width,
    onLoadSuccess,
    onLoadError,
    getPageHeight,
  };
};

export default usePDFViewerData;
