import { PropertyAPI } from "@griffingroupglobal/eslib-api";

/**
 * Fetch all properties
 * @returns - @returns {Promise<Object>} An object containing properties, dictionary and dropdown options.
 */
const fetchPropertiesOverview = async (signal) => {
  const { data } = await PropertyAPI.getWOP(
    "overview?projections=members,timezone,vendors,spaces",
    { signal }
  );

  const propertiesDD = Object.values(data).map((property) => {
    return {
      label: property?.title,
      value: property?.reference,
      id: property?.id,
    };
  });

  return {
    propertiesDict: data,
    propertiesDD,
  };
};

export default fetchPropertiesOverview;
