import React from "react";
import Modal from "../Modal/Modal";
import ServiceRequestForm from "./ServiceRequestForm";
import useServiceRequestModalData from "./useServiceRequestModalData";

const ServiceRequestsModal = () => {
  const {
    isOpen,
    requestForm,
    disableForm,
    disableAssociationDropdown,
    showConfirm,
    lockAssociation,
    parentResource,
    ticketTypeOptions,
    assetOptions,
    associationAsset,
    handleSelectAsset,
    handleCreateServiceRequest,
    handleChangeForm,
    handleCloseModal,
    handleTags,
    handleAddFiles,
    handleRemoveFile,
    handleChangeAssociation,
  } = useServiceRequestModalData();

  return (
    <Modal
      id="modalRequestId"
      title="Create Request"
      primaryButtonTitle="Save"
      tertiaryButtonTitle="Cancel"
      modalAction="Request Creation"
      isOpen={isOpen}
      primaryButtonOnClick={handleCreateServiceRequest}
      onRequestModalClose={handleCloseModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
      trashCan
      showConfirm={showConfirm}
      disabled={disableForm}
      buttonCnClass="-ml-3"
    >
      <ServiceRequestForm
        requestForm={requestForm}
        disableAssociationDropdown={disableAssociationDropdown}
        lockAssociation={lockAssociation}
        parentResource={parentResource}
        ticketTypeOptions={ticketTypeOptions}
        handleChangeForm={handleChangeForm}
        handleTags={handleTags}
        handleAddFiles={handleAddFiles}
        handleRemoveFile={handleRemoveFile}
        handleChangeAssociation={handleChangeAssociation}
        assetOptions={assetOptions}
        associationAsset={associationAsset}
        handleSelectAsset={handleSelectAsset}
      />
    </Modal>
  );
};

export default ServiceRequestsModal;
