import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router";
import {
  ADD_OPEN_MODAL,
  PROPERTY,
  PROPERTY_CREATE_MODAL,
} from "../../../constants";
import PropertyTable from "../../../stories/Components/PropertyTable/PropertyTable";
import useCurrentUser from "../../../hooks/useCurrentUser";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import { useModalState } from "../../../state/modalState";
import { getSingleResourcePath } from "../../../helpers/Navigation";
import { hasWriteAdministrativePermission } from "../../../helpers/Permissions";

const PropertyList = () => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const [, modalDispatch] = useModalState();

  const onPropertyRowClick = (id) => {
    history.push(getSingleResourcePath(`Property/${id}`));
  };

  return (
    <>
      <PrimaryHeaderItems
        headerTitle="Properties"
        buttonTitle="Property"
        customButton={
          hasWriteAdministrativePermission(PROPERTY, currentUser) && (
            <PrimaryButton
              resourceName="Property"
              onClick={() =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalType: PROPERTY_CREATE_MODAL,
                })
              }
              addButton
            />
          )
        }
      />

      <PropertyTable onRowClick={onPropertyRowClick} />
    </>
  );
};

PropertyList.propTypes = {};

PropertyList.defaultProps = {};

export default PropertyList;
